import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import actions from "redux/prompts/actions";
import { ServiceReturn } from "redux/types";
import { setGeminiPromptsListener } from "services/listeners/prompts";
import {
  createGeminiPrompt,
  getActiveSupplierPrompts,
  updateGeminiPrompt,
} from "services/prompts";
import { GeminiPrompt, PromptCategory } from "./types";

function* SUBSCRIBE_TO_GEMINI_PROMPTS() {
  yield put(actions.SET_STATE({ loading: true, geminiPrompts: [] }));

  const channel: EventChannel<boolean> = yield call(setGeminiPromptsListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_GEMINI_PROMPTS);
    channel.close();
  });

  try {
    while (true) {
      const geminiPrompts: GeminiPrompt[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          geminiPrompts,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loading: false }));
    }
  }
}

export interface CREATE_GEMINI_PROMPT_Payload {
  prompt: string;
  category: PromptCategory;
  supplierId?: string;
}

export function* CREATE_GEMINI_PROMPT(
  input: ReturnType<typeof actions.CREATE_GEMINI_PROMPT>
) {
  const { prompt, category, supplierId } = input.payload;
  const createdBy: string = yield select((state) => state.auth.user) ?? "";

  yield put(actions.SET_STATE({ loadingAdd: true }));

  const result: ServiceReturn = yield call(
    createGeminiPrompt,
    prompt,
    category,
    createdBy,
    supplierId
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Prompt Created"));
    yield put(actions.SET_STATE({ loadingAdd: false }));
  } else {
    yield put(alertActions.ERROR(result.error));
    yield put(actions.SET_STATE({ loadingAdd: false }));
  }
}

export interface UPDATE_GEMINI_PROMPT_Payload {
  id: string;
  data: Partial<GeminiPrompt>;
}

export function* UPDATE_GEMINI_PROMPT(
  input: ReturnType<typeof actions.UPDATE_GEMINI_PROMPT>
) {
  const { data, id } = input.payload;

  yield put(actions.SET_STATE({ loadingUpdate: true }));

  const result: ServiceReturn = yield call(updateGeminiPrompt, id, data);

  if (result.data) {
    yield put(alertActions.SUCCESS("Prompt Updated"));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  } else {
    yield put(alertActions.ERROR(result.error));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  }
}

export function* GET_ACTIVE_SUPPLIER_PROMPTS() {
  yield put(actions.SET_STATE({ loading: true }));

  const result: ServiceReturn = yield call(getActiveSupplierPrompts);

  if (result.data) {
    yield put(actions.SET_STATE({ activeSupplierPrompts: result.data }));
  } else {
    yield put(alertActions.ERROR(result.error));
  }
  yield put(actions.SET_STATE({ loading: false }));
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.SUBSCRIBE_TO_GEMINI_PROMPTS,
      SUBSCRIBE_TO_GEMINI_PROMPTS
    ),
    takeLatest(actions.CREATE_GEMINI_PROMPT, CREATE_GEMINI_PROMPT),
    takeLatest(actions.UPDATE_GEMINI_PROMPT, UPDATE_GEMINI_PROMPT),
    takeLatest(
      actions.GET_ACTIVE_SUPPLIER_PROMPTS,
      GET_ACTIVE_SUPPLIER_PROMPTS
    ),
  ]);
}
