import store from "redux/store";

export function isAllowedToSeeAllInvoices() {
  const authClaims = store.getState().auth.authClaims;
  const loadAllInvoices = store.getState().settings.loadAllInvoices;
  return (
    (authClaims?.admin ?? false) ||
    (authClaims?.dev ?? false) ||
    loadAllInvoices
  );
}
