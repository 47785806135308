import { AuthClaims } from "redux/auth/types";
import store from "redux/store";

export const claims = {
  // Claims
  admin: "admin",
  dev: "dev",
  headDataManager: "headDataManager",
  invoices: "invoices",
  headDataSupervisor: "headDataSupervisor",
  supervisor: "supervisor",

  // Modules
  apTransactions: "apTransactions",
  dataLabeling: "dataLabeling",
  dataVoting: "dataVoting",
  userStatus: "userStatus",
  docs: "docs",
  users: "users",
  suppliers: "suppliers",
  lists: "lists",
  silhouettes: "silhouettes",
  counts: "counts",
  sales: "sales",
  integrationMonitor: "integrationMonitor",
  appTutorials: "appTutorials",
  permissions: "permissions",
  loginToken: "loginToken",
  tablePDFtoCSV: "tablePDFtoCSV",
  scripts: "scripts",
  predefinedFilterManager: "predefinedFilterManager",
  prompts: "prompts",
  revenueMapping: "revenueMapping",

  /** Features
   * Permission related for features
   */

  // SETTINGS
  viewAs: "viewAsOtherRoles",
  settings: "settings",
  loadAllUsers: "loadAllUsers",
  loadAllInvoices: "loadAllInvoices",

  // USERS
  createUser: "createUser",
  updateUserPriority: "updateUserPriority",

  // INVOICES
  checkingPriorityList: "checkingPriorityList",
  resolvingInvoices: "resolvingInvoices",
  viewCheckedAndDeletedInv: "viewCheckedAndDeletedInv",
  checkInvoice: "checkInvoice",
  editInvoiceNotes: "editInvoiceNotes",
  markAllInvAsChecked: "markAllInvAsChecked",
  deleteInvoice: "deleteInvoice",
  undeleteInvoice: "undeleteInvoice",
  deleteInvoiceImage: "deleteInvoiceImage",
  accessInvoices: "accessInvoices",
  viewInvoiceItemsFoundInNewerInvoices: "viewInvoiceItemsFoundInNewerInvoices",
  viewCumulativePriceChanges: "viewCumulativePriceChanges",
  voterEngine: "voterEngine",
  uploadAndCreateInvoice: "uploadAndCreateInvoice",
  addSupplier: "addSupplier",
  viewItemOnResolvedInvoices: "viewItemOnResolvedInvoices",
  invoiceItemForUserCheckbox: "invoiceItemForUserCheckbox",
  accessOrders: "accessOrders",

  // LISTS
  accessUserItems: "accessUserItems",
  accessMaterialBookkeepingItems: "accessMaterialBookkeepingItems",
  accessZeroCostItemTask: "accessZeroCostItemTask",

  // ASSUMPTION DATE FILTERS
  setAssumptionDateFilter: "setAssumptionDateFilter",
  toggleAssumptionDateFilteredInvoices: "toggleAssumptionDateFilteredInvoices",

  // ADVANCED FILTERS
  advancedFilter: "advancedFilter",
  viewSavedAdvancedFilters: "viewSavedAdvancedFilters",
  createAdvancedFilters: "createAdvancedFilters",
  editSavedAdvancedFilters: "editSavedAdvancedFilters",

  // AP TRANSACTIONS
  setAPUploadRecipients: "setAPUploadRecipients",
  markAllApAsResolved: "markAllApAsResolved",
  uploadAndCreateTransactions: "uploadAndCreateTransactions",

  // POS ITEM TASKS
  accessPOSItemTasks: "accessPOSItemTasks",
  leavePOSItemUnconnected: "leavePOSItemUnconnected",
  POSItemJsonData: "POSItemJsonData",
  checkPosItemTask: "checkPosItemTask",
  viewCheckedTask: "viewCheckedTasks",

  // VOTES MODULE (NOT DATA VOTING)
  createVote: "createVote",
  editVote: "editVote",
  deleteVote: "deleteVote",
  closeVote: "closeVote",

  // DATA VOTING (INVOICE VOTING RESULTS)
  accessInvoiceVotesResults: "accessInvoiceVotesResults",

  // GENERAL PERMISSIONS
  viewNotifications: "viewNotifications",

  // INTEGRATION MONITOR
  manageIntegrations: "manageIntegrations",
  editIntegrations: "editIntegrations",

  // SALES
  markSalesAsDeleted: "markSalesAsDeleted",
};

// TEMPORARY PERMISSIONS FOR A SPECIAL DATA MANAGER
const checkInvoicesModules = [claims.checkInvoice, claims.checkingPriorityList];
const viewScripts = [claims.scripts];

export const hasAccess = (
  authClaims: AuthClaims | undefined,
  module: string
): boolean => {
  let isAllowed = false;
  const permissions = store.getState().appConfig.permissions;
  if (authClaims) {
    // TEMPORARY PERMISSIONS FOR A SPECIAL DATA MANAGER
    if (authClaims.checkInvoices && checkInvoicesModules.includes(module)) {
      isAllowed = true;
    }
    if (authClaims.scripts && viewScripts.includes(module)) {
      isAllowed = true;
    }

    const permission = permissions.find((p) => p.code === module);
    if (
      permission?.roles?.some((role) => authClaims[role as keyof AuthClaims]) ||
      permission?.userIds?.some((id) => id === authClaims.user_id)
    ) {
      isAllowed = true;
    }
  }
  return isAllowed;
};

// this list are referred to AuthClaims interface
const authClaimTypes = [
  "admin",
  "headDataManager",
  "invoices",
  "headDataSupervisor",
  "supervisor",
  "dev",
  "checkInvoices",
  "olderInvoices",
];
export const checkDataManager = (authClaims: AuthClaims | undefined) => {
  if (!authClaims) return false;
  if (!authClaims.invoices) return false;

  // check if user has any other claims besides invoices
  let isDataManager = true;
  for (const claim of authClaimTypes) {
    if (claim === "invoices") continue;
    if (authClaims[claim]) {
      isDataManager = false;
      break;
    }
  }

  return isDataManager;
};

export const hasOnlySpecifiedRole = (
  role: string,
  authClaims?: AuthClaims
): boolean => {
  if (!authClaims) return false;

  const isKeyRoleValueTrue = authClaims[role as keyof AuthClaims] === true;
  return (
    isKeyRoleValueTrue &&
    Object.keys(authClaims)
      .filter((x) => x !== role)
      .every((key) => authClaims[key as keyof AuthClaims] === false)
  );
};
