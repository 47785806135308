import { createReducer } from "@reduxjs/toolkit";
import actions from "redux/items/actions";
import { ItemsState } from "./types";

export const initialState: ItemsState = {
  itemLoading: true,
  globalLoading: true,
  itemGlobalAssignmentLoading: false,
  loadingSaving: false,
  recipesLoading: true,
  items: [],
  queriedUserItemsCount: 0,
  globalItems: [],
  silhouettes: [],
  recipes: [],
  searchMoreLoading: false,
  loadingZeroCostItemTasks: false,
  zeroCostItemTasks: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
