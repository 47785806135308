import { Invoice } from "redux/invoice/types";
import { CUSTOM_TAGS } from "../tag.helpers";

export const sortByCreatedAtOrOldestDate = (
  x: Invoice,
  y: Invoice,
  byOldestDate: boolean
): number => {
  if (byOldestDate) {
    if (!x.oldestDate && !y.oldestDate) {
      return (
        (x.createdAt ?? new Date()).valueOf() -
        (y.createdAt ?? new Date()).valueOf()
      );
    } else if (!x.oldestDate) {
      return -1;
    } else if (!y.oldestDate) {
      return 1;
    } else {
      return (
        (x.oldestDate ?? new Date()).valueOf() -
        (y.oldestDate ?? new Date()).valueOf()
      );
    }
  } else {
    return (
      (x.createdAt ?? new Date()).valueOf() -
      (y.createdAt ?? new Date()).valueOf()
    );
  }
};

export const sortByCreatedAtNewest = (x: Invoice, y: Invoice): number => {
  const createdAtX = x.createdAt ? new Date(x.createdAt) : new Date();
  const createdAtY = y.createdAt ? new Date(y.createdAt) : new Date();
  const res = createdAtY.valueOf() - createdAtX.valueOf();
  return res;
};

export const sortByDeliveryDateOldest = (x: Invoice, y: Invoice): number => {
  const deliveryDateX = x.deliveryDate ? new Date(x.deliveryDate) : new Date();
  const deliveryDateY = y.deliveryDate ? new Date(y.deliveryDate) : new Date();
  const res = deliveryDateX.valueOf() - deliveryDateY.valueOf();
  return res;
};

export const sortByDeliveryDateNewest = (x: Invoice, y: Invoice): number => {
  const deliveryDateX = x.deliveryDate ? new Date(x.deliveryDate) : new Date();
  const deliveryDateY = y.deliveryDate ? new Date(y.deliveryDate) : new Date();
  const res = deliveryDateY.valueOf() - deliveryDateX.valueOf();
  return res;
};

export const sortByOldestDateAscending = (a: Invoice, b: Invoice): number => {
  const aDate = a.oldestDate;
  const bDate = b.oldestDate;

  if (!aDate && !bDate) return 0;

  if (!aDate) return 1;

  if (!bDate) return -1;

  const aMilis = aDate.valueOf();
  const bMilis = bDate.valueOf();

  return aMilis - bMilis;
};

export const isValidNumber = (value: unknown) =>
  typeof value === "number" && isFinite(value) && !isNaN(value);

export const viewOrderOnInvoicesUrlConstructor = (
  userId: string,
  orderId: string
) => {
  const url = new URL(window.location.origin);
  url.pathname = "/data-labeling/orders-resolving/" + userId;
  url.searchParams.append("search", "");
  url.searchParams.append("tab", "resolved");
  url.searchParams.append("sub", "unchecked,checked");
  url.searchParams.append("invoiceId", orderId);
  return url.href;
};

export const isInvoiceHeaderDataValid = (inv: Invoice) =>
  inv.tags?.includes("diverseInvoice") ||
  (inv.supplierId &&
    inv.supplierName &&
    !inv.tags?.includes("openSupplier") &&
    !inv.tagsCount?.[CUSTOM_TAGS.OPEN_SUPPLIER.name]);

export const isInvoiceCheckable = (inv: Invoice) =>
  inv.state === "resolved" &&
  !inv.deleted &&
  !inv.tags?.includes("openItem") &&
  !inv.tagsCount?.openItem;
