import { processFile, processToImages } from "components/ap-transactions/utils";
import moment from "moment";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  delay,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import store from "redux/store";
import { ServiceReturn } from "redux/types";
import {
  createInvoiceWithFiles,
  getFile,
  getFiles,
  markTransactionAsSensitive,
  uploadAndCreateTransactions,
  uploadInvoiceFile,
} from "services/ap-transactions";
import {
  setApMetricsListener,
  tableViewTransactionsListener,
} from "services/listeners/ap-transaction";
import { setUserStatusTransactionsListener } from "services/listeners/users";
import { COMMON, INVOICE } from "utils/constants";
import usersActions from "../users/actions";
import actions from "./actions";
import {
  APImageData,
  APImageDataUpload,
  APTransaction,
  ClassificationCountData,
  UserStatusTransactions,
} from "./types";

export interface GET_IMAGE_Payload {
  id: string;
  fileName: string[];
}

export function* GET_IMAGE({ payload }: ReturnType<typeof actions.GET_IMAGE>) {
  const { id, fileName } = payload;

  yield put(actions.ADD_IMAGE({ id, data: null, loading: true }));
  yield put(actions.SET_STATE({ getImageProgress: 0 }));

  const data: APImageData[] = [];
  for (let i = 0; i < fileName.length; i++) {
    if (!fileName[i]) continue;

    let imageError: any = null;
    const result: ServiceReturn = yield call(getFile, fileName[i]);
    if (result.data) {
      const processedImage: APImageData[] = yield call(
        processFile,
        result.data,
        fileName[i]
      );
      for (const image of processedImage) {
        if (image.error) imageError = image.error;
        data.push(image);
      }
      if (imageError) {
        yield put(
          alertActions.ERROR(imageError.message || COMMON.REQUEST_ERROR)
        );
      }
    } else {
      yield put(alertActions.ERROR(COMMON.REQUEST_ERROR));
    }

    yield put(
      actions.SET_STATE({ getImageProgress: ((i + 1) / fileName.length) * 100 })
    );
  }
  yield put(actions.ADD_IMAGE({ id, data, loading: false }));
  yield put(actions.SET_STATE({ getImageProgress: 0 }));
}

export interface GET_IMAGES_Payload {
  paths: { id: string; fileName: string[] }[];
  batchSize: number;
}

export function* GET_IMAGES({
  payload,
}: ReturnType<typeof actions.GET_IMAGES>) {
  const { paths, batchSize } = payload;

  yield put(
    actions.SET_STATE({
      loadingImages: true,
      loadingFirstImages: true,
    })
  );

  let data: { id: string; loading: boolean; data: APImageData[] | null }[] = [];

  let batchStart = 0;
  while (batchStart < paths.length) {
    const batchEnd = Math.min(batchStart + batchSize, paths.length);
    const batchPaths = paths.slice(batchStart, batchEnd);

    for (let i = 0; i < batchPaths.length; i++) {
      const result: ServiceReturn = yield call(
        getFiles,
        batchPaths[i].fileName
      );
      if (result.data) {
        const processedImage: APImageData[] = yield call(
          processToImages,
          result.data,
          batchPaths[i].fileName
        );
        const newData = {
          id: batchPaths[i].id,
          loading: false,
          data: processedImage,
        };
        data = [...data, newData];
        if (batchStart === 0 && i === 0) {
          yield put(
            actions.SET_STATE({
              loadingFirstImages: false,
              image: data,
            })
          );
        }
      }
      if (result.error) {
        yield put(
          alertActions.ERROR(
            result.error.map((err: any) => err.message).join("\n") ||
              COMMON.REQUEST_ERROR
          )
        );
      }
    }

    batchStart += batchSize;
    yield put(
      actions.SET_STATE({
        loadingImages: true,
        image: data,
      })
    );

    // Wait the current batch to finish the preload before load to sthe next batch
    yield delay(1000);
  }

  yield put(
    actions.SET_STATE({
      loadingImages: false,
      loadingFirstImages: false,
      image: data,
    })
  );
}

export interface CREATE_INVOICE_Payload {
  apUserId: string;
  invUserId: string;
  selectedImageData: APImageDataUpload[];
  transactionId: string;
  resolvedBy: string;
  hasThumbnails: boolean;
  completeImagesData?: APImageData[];
  isUsingRowUploadButton?: boolean;
}
export function* CREATE_INVOICE(
  input: ReturnType<typeof actions.CREATE_INVOICE>
) {
  const {
    apUserId,
    invUserId,
    selectedImageData,
    transactionId,
    resolvedBy,
    hasThumbnails,
    completeImagesData,
    isUsingRowUploadButton,
  } = input.payload;

  // Optimistic update, set the transaction to resolved
  const transactions = store.getState().users.userTransactions;
  const rowLoading = store.getState().apTransactions.rowUploadInvoiceLoading;

  if (isUsingRowUploadButton) {
    yield put(
      actions.SET_STATE({
        rowUploadInvoiceLoading: {
          ...rowLoading,
          [transactionId]: true,
        },
      })
    );
  } else {
    yield put(
      actions.SET_STATE({
        loadingUpload: true,
      })
    );
  }

  const result: ServiceReturn = yield call(
    createInvoiceWithFiles,
    apUserId,
    invUserId,
    selectedImageData,
    transactionId,
    resolvedBy,
    hasThumbnails,
    completeImagesData
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Invoice created."));
    yield put(
      usersActions.SET_STATE({
        userTransactions: transactions.map((t) =>
          t.id === transactionId ? { ...t, resolved: true } : t
        ),
      })
    );
    if (isUsingRowUploadButton) {
      yield put(
        actions.SET_STATE({
          rowUploadInvoiceLoading: {
            ...rowLoading,
            [transactionId]: false,
          },
        })
      );
    } else {
      yield put(
        actions.SET_STATE({
          loadingUpload: false,
        })
      );
    }
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    if (isUsingRowUploadButton) {
      yield put(
        actions.SET_STATE({
          rowUploadInvoiceLoading: {
            ...rowLoading,
            [transactionId]: false,
          },
        })
      );
    } else {
      yield put(
        actions.SET_STATE({
          loadingUpload: false,
        })
      );
    }
  }
}

export interface ADD_INVOICE_IMAGE_Payload {
  userId: string;
  fileName: string;
  fileData: ArrayBuffer;
}

export function* ADD_INVOICE_IMAGE(
  input: ReturnType<typeof actions.ADD_INVOICE_IMAGE>
) {
  const { userId, fileName, fileData } = input.payload;

  const result: ServiceReturn = yield call(
    uploadInvoiceFile,
    userId,
    fileName,
    fileData
  );

  if (result.data) {
    yield put(alertActions.SUCCESS(INVOICE.IMAGE_UPLOADED));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface MARK_TRANSACTION_AS_SENSITIVE_Payload {
  userId: string;
  transactionId: string;
  isSensitive: boolean;
}
export function* MARK_TRANSACTION_AS_SENSITIVE(
  input: ReturnType<typeof actions.MARK_TRANSACTION_AS_SENSITIVE>
) {
  const { userId, transactionId, isSensitive } = input.payload;

  const result: ServiceReturn = yield call(
    markTransactionAsSensitive,
    userId,
    transactionId,
    isSensitive
  );

  if (result.data) {
    if (result.data === "marked") {
      yield put(alertActions.SUCCESS("Transaction marked as sensitive."));
    } else {
      yield put(alertActions.WARNING("Transaction unmarked as sensitive."));
    }
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS_Payload {
  userId: string;
}
export function* SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS>
) {
  yield put(
    actions.SET_USER_STATUS_TRANSACTIONS({
      loadingUnresolved: true,
    })
  );
  const { userId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserStatusTransactionsListener,
    userId,
    false
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_UNRESOLVED_TRANSACTIONS);
    channel.close();
    yield put(actions.SET_STATE({ userStatus: {} as UserStatusTransactions }));
  });

  try {
    while (true) {
      const unresolved: APTransaction[] = yield take(channel);

      yield put(
        actions.SET_USER_STATUS_TRANSACTIONS({
          unresolved,
          loadingUnresolved: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_USER_STATUS_TRANSACTIONS({ loadingUnresolved: false })
      );
    }
  }
}

export function* SUBSCRIBE_TO_TRANSACTIONS_TABLE_VIEW() {
  yield put(
    actions.SET_STATE({
      tableViewLoading: true,
    })
  );
  const channel: EventChannel<boolean> = yield call(
    tableViewTransactionsListener
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_TABLE_VIEW_TRANSACTIONS);
    channel.close();
    yield put(actions.SET_STATE({ tableViewTransactions: [] }));
  });

  try {
    while (true) {
      const invoiceTransactions: APTransaction[] = yield take(channel);

      yield put(
        actions.SET_STATE({
          tableViewTransactions: invoiceTransactions,
          tableViewLoading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ tableViewLoading: false }));
    }
  }
}

export interface SUBSCRIBE_TO_RESOLVED_TRANSACTIONS_Payload {
  userId: string;
  maxResolvedTransactions?: number;
}
export function* SUBSCRIBE_TO_RESOLVED_TRANSACTIONS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_RESOLVED_TRANSACTIONS>
) {
  yield put(
    actions.SET_USER_STATUS_TRANSACTIONS({
      loadingResolved: true,
    })
  );
  const { userId, maxResolvedTransactions = 15 } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserStatusTransactionsListener,
    userId,
    true,
    maxResolvedTransactions
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_RESOLVED_TRANSACTIONS);
    channel.close();
    yield put(actions.SET_STATE({ userStatus: {} as UserStatusTransactions }));
  });

  try {
    while (true) {
      const resolved: APTransaction[] = yield take(channel);

      yield put(
        actions.SET_USER_STATUS_TRANSACTIONS({
          resolved,
          loadingResolved: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_USER_STATUS_TRANSACTIONS({ loadingResolved: false })
      );
    }
  }
}

export interface GET_AP_METRICS_Payload {
  dateFrom: Date;
  dateTo: Date;
}

export function* SUBSCRIBE_TO_AP_METRICS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_AP_METRICS>
) {
  const { dateFrom, dateTo } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingApMetrics: true,
      apMetrics: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setApMetricsListener,
    dateFrom,
    dateTo
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_AP_METRICS);
    channel.close();
    yield put(
      actions.SET_STATE({
        apMetrics: [],
      })
    );
  });

  try {
    while (true) {
      const data: ClassificationCountData[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          apMetrics: data,
          loadingApMetrics: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingApMetrics: false }));
    }
  }
}

export interface UPLOAD_AND_CREATE_TRANSACTIONS_Payload {
  userId: string;
  files: File[];
}
export function* UPLOAD_AND_CREATE_TRANSACTIONS(
  input: ReturnType<typeof actions.UPLOAD_AND_CREATE_TRANSACTIONS>
) {
  yield put(
    actions.SET_STATE({
      loadingUploadAndCreateTransactions: true,
    })
  );
  const { userId, files } = input.payload;

  const result: ServiceReturn = yield call(
    uploadAndCreateTransactions,
    userId,
    files
  );

  if (result.data) {
    yield put(
      alertActions.SUCCESS(`Successfully created ${files.length} transactions.`)
    );

    yield put(
      actions.SET_STATE({
        loadingUploadAndCreateTransactions: false,
      })
    );
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(
      actions.SET_STATE({
        loadingUploadAndCreateTransactions: false,
      })
    );
  }
}

export function* SUBSCRIBE_TO_TODAY_AP_METRICS() {
  const dateFrom = moment().startOf("day").utc().toDate();
  const dateTo = moment().endOf("day").utc().toDate();

  yield put(
    actions.SET_STATE({
      loadingApMetrics: true,
      todayApMetric: undefined,
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setApMetricsListener,
    dateFrom,
    dateTo,
    true
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_TODAY_AP_METRICS);
    channel.close();
    yield put(
      actions.SET_STATE({
        todayApMetric: undefined,
      })
    );
  });

  try {
    while (true) {
      const data: ClassificationCountData[] = yield take(channel);

      yield put(
        actions.SET_STATE({
          todayApMetric: data[0],
          loadingApMetrics: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingApMetrics: false }));
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_IMAGE, GET_IMAGE),
    takeLatest(actions.GET_IMAGES, GET_IMAGES),
    takeLatest(actions.CREATE_INVOICE, CREATE_INVOICE),
    takeLatest(actions.ADD_INVOICE_IMAGE, ADD_INVOICE_IMAGE),
    takeLatest(
      actions.MARK_TRANSACTION_AS_SENSITIVE,
      MARK_TRANSACTION_AS_SENSITIVE
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS,
      SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_RESOLVED_TRANSACTIONS,
      SUBSCRIBE_TO_RESOLVED_TRANSACTIONS
    ),
    takeLatest(actions.SUBSCRIBE_TO_AP_METRICS, SUBSCRIBE_TO_AP_METRICS),
    takeLatest(
      actions.UPLOAD_AND_CREATE_TRANSACTIONS,
      UPLOAD_AND_CREATE_TRANSACTIONS
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_TODAY_AP_METRICS,
      SUBSCRIBE_TO_TODAY_AP_METRICS
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_TABLE_VIEW_TRANSACTIONS,
      SUBSCRIBE_TO_TRANSACTIONS_TABLE_VIEW
    ),
  ]);
}
