import { DateRange } from "@blueprintjs/datetime2";
import { isAllowedToSeeAllInvoices } from "components/invoices/permission.helpers";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { checkDataManager } from "permissions";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { getAuthUserClaims } from "redux/auth/helpers";
import { RootState } from "redux/store";
import { ServiceReturn } from "redux/types";
import {
  addDownloadURL,
  batchCheckInvoices,
  checkInvoice,
  deleteImage,
  deleteVotes,
  fileUpload,
  getDocumentAIData,
  getOcrData,
  updateDocumentAI,
  updateInvoice,
  updateVote,
  uploadAndCreateInvoice,
  voteInvoice,
} from "services/invoice";
import { addUserItems, updateUserItems } from "services/items";
import {
  setAllUserInvoiceListener,
  setInvoiceCheckingMetricsListener,
  setInvoiceCheckingTableListener,
  setInvoiceFeesListener,
  setInvoiceResolvingMetricsListener,
  setInvoiceVotesListener,
  setUserInvoiceListener,
  setUserInvoiceTableListener,
  setUserInvoiceVotesListener,
  setUserOrdersListener,
  setUserStatusInvoiceListener,
  setUserVotesResultListener,
} from "services/listeners/invoice";
import { setUserItemsListener } from "services/listeners/items";
import { COMMON, INVOICE } from "utils/constants";
import actions from "./actions";
import {
  CheckedInvoice,
  DocumentAI,
  Fee,
  Invoice,
  InvoiceCheckingMetric,
  InvoiceResolvingMetric,
  InvoiceVote,
  Items,
  UserStatusInvoices,
} from "./types";

export type SUBSCRIBE_TO_FEES_payload = {
  userId: string;
};

export function* SUBSCRIBE_TO_FEES(
  inp: ReturnType<typeof actions.SUBSCRIBE_TO_FEES>
) {
  const channel: EventChannel<boolean> = yield call(
    setInvoiceFeesListener,
    inp.payload.userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_FEES);
    channel.close();
  });

  try {
    while (true) {
      const fees: Fee[] = yield take(channel);
      yield put(actions.SET_STATE({ fees }));
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}

export interface SUBSCRIBE_TO_USER_INVOICE_Payload {
  userId: string;
  state?: string;
}
export function* SUBSCRIBE_TO_USER_INVOICE(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_INVOICE>
) {
  yield put(
    actions.SET_STATE({
      loadingInvoice: true,
      invoices: [],
    })
  );
  const { userId, state } = input.payload;
  const isAdminOrAllowedToSeeAllInvoices = isAllowedToSeeAllInvoices();

  const isDataManager = checkDataManager(getAuthUserClaims());
  const oldestDateFilter: DateRange = yield select(
    (state: RootState) => state.settings.invoiceAssumptionDateFilter
  );
  const channel: EventChannel<boolean> = yield call(
    setUserInvoiceListener,
    userId,
    isAdminOrAllowedToSeeAllInvoices,
    state,
    isDataManager,
    oldestDateFilter
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_INVOICE);
    channel.close();
  });

  try {
    while (true) {
      const invoices: Invoice[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          invoices,
          userId,
          loadingInvoice: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingInvoice: false }));
    }
  }
}

export function* SUBSCRIBE_TO_USER_ORDERS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_ORDERS>
) {
  yield put(
    actions.SET_STATE({
      loadingInvoice: true,
      invoices: [],
    })
  );
  const { userId, state } = input.payload;

  const channel: EventChannel<boolean> = yield call(
    setUserOrdersListener,
    userId,
    state
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_ORDERS);
    channel.close();
  });

  try {
    while (true) {
      const invoices: Invoice[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          invoices,
          userId,
          loadingInvoice: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingInvoice: false }));
    }
  }
}

export function* SUBSCRIBE_TO_USER_INVOICE_VOTES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_INVOICE_VOTES>
) {
  yield put(
    actions.SET_STATE({
      loadingInvoiceVotes: true,
      invoiceVotes: [],
    })
  );
  const { userId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserInvoiceVotesListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_INVOICE_VOTES);
    channel.close();
  });

  try {
    while (true) {
      const invoices: Invoice[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          invoiceVotes: invoices,
          userId,
          loadingInvoiceVotes: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingInvoiceVotes: false }));
    }
  }
}

export type SUBSCRIBE_TO_TABLE_VIEW_INVOICES_Payload = {
  direction?: "next" | "prev";
  dateFrom?: Date;
  dateTo?: Date;
  isChecking?: boolean;
};

export function* SUBSCRIBE_TO_TABLE_VIEW_INVOICES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_TABLE_VIEW_INVOICES>
) {
  yield put(
    actions.SET_STATE({
      loadingInvoice: true,
    })
  );
  const payload = input.payload;
  const isAdmin = getAuthUserClaims()?.admin;

  const channel: EventChannel<boolean> = yield call(
    payload.isChecking
      ? setInvoiceCheckingTableListener
      : setAllUserInvoiceListener,
    {
      direction: payload.direction,
      dateFromInput: payload.dateFrom,
      dateToInput: payload.dateTo,
      isChecking: payload.isChecking,
      isAdmin,
    }
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_TABLE_VIEW_INVOICES);
    channel.close();
    yield put(
      actions.SET_STATE({
        tableViewInvoices: {
          data: [],
          dateFrom: payload.dateFrom || new Date(),
          dateTo: payload.dateTo || new Date(),
        },
      })
    );
  });

  try {
    while (true) {
      const { data, dateFrom, dateTo } = yield take(channel);

      yield put(
        actions.SET_STATE({
          tableViewInvoices: {
            data,
            dateFrom: dateFrom ?? payload.dateFrom ?? new Date(),
            dateTo: dateTo ?? payload.dateTo ?? new Date(),
          },
          loadingInvoice: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingInvoice: false,
        })
      );
    }
  }
}

export interface SUBSCRIBE_TO_USER_INVOICE_TABLE_Payload {
  userId: string;
  invoiceId: string;
}
export function* SUBSCRIBE_TO_USER_INVOICE_TABLE(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_INVOICE_TABLE>
) {
  const { userId, invoiceId } = input.payload;
  const userInvoicesTable: {
    [userId: string]: {
      loading: boolean;
      invoices: Invoice[];
    };
  } = yield select((state: RootState) => state.invoices.userInvoicesTable);
  if (!userInvoicesTable[userId])
    yield put(
      actions.UPDATE_USER_INVOICE_TABLE({
        userId,
        invoice: null,
        loading: true,
      })
    );
  const channel: EventChannel<boolean> = yield call(
    setUserInvoiceTableListener,
    userId,
    invoiceId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_INVOICE_TABLE);
    channel.close();
  });

  try {
    while (true) {
      const invoice: Invoice = yield take(channel);

      yield put(
        actions.UPDATE_USER_INVOICE_TABLE({
          userId,
          invoice,
          loading: false,
        })
      );
    }
  } catch (e) {
    yield put(alertActions.ERROR((e instanceof Error && e.message) || "Error"));
  }
}

export function* SUBSCRIBE_TO_USER_ITEMS_TABLE(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_ITEMS_TABLE>
) {
  const { userId } = input.payload;
  const userItemsTable: {
    [userId: string]: {
      loading: boolean;
      userItems: Items[];
    };
  } = yield select((state: RootState) => state.invoices.userItemsTable);

  for (const key in userItemsTable) {
    if (key !== userId) {
      yield put(
        actions.UPDATE_USER_ITEMS_TABLE({
          userId: key,
          userItems: [],
          loading: true,
        })
      );
    }
  }

  yield put(
    actions.UPDATE_USER_ITEMS_TABLE({
      userId,
      userItems: [],
      loading: true,
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setUserItemsListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_ITEMS_TABLE);
    channel.close();
  });

  try {
    while (true) {
      const userItems: Items[] = yield take(channel);
      yield put(
        actions.UPDATE_USER_ITEMS_TABLE({
          userId,
          userItems,
          loading: false,
        })
      );
    }
  } catch (e) {
    yield put(alertActions.ERROR((e instanceof Error && e.message) || "Error"));
  }
}

export function* SUBSCRIBE_TO_USER_FEES_TABLE(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_FEES_TABLE>
) {
  const { userId } = input.payload;
  const userFeesTable: {
    [userId: string]: {
      loading: boolean;
      userFees: Fee[];
    };
  } = yield select((state: RootState) => state.invoices.userFeesTable);

  for (const key in userFeesTable) {
    if (key !== userId) {
      yield put(
        actions.UPDATE_USER_FEES_TABLE({
          userId: key,
          userFees: [],
          loading: true,
        })
      );
    }
  }

  yield put(
    actions.UPDATE_USER_FEES_TABLE({
      userId,
      userFees: [],
      loading: true,
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setInvoiceFeesListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_FEES_TABLE);
    channel.close();
  });

  try {
    while (true) {
      const userFees: Fee[] = yield take(channel);
      yield put(
        actions.UPDATE_USER_FEES_TABLE({
          userId,
          userFees,
          loading: false,
        })
      );
    }
  } catch (e) {
    yield put(alertActions.ERROR((e instanceof Error && e.message) || "Error"));
  }
}
export interface SUBSCRIBE_TO_USER_ITEMS_Payload {
  userId: string;
}
export function* SUBSCRIBE_TO_USER_ITEMS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_ITEMS>
) {
  yield put(
    actions.SET_STATE({
      loadingItems: true,
      userItems: [],
    })
  );
  const { userId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserItemsListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_ITEMS);
    channel.close();
  });

  try {
    while (true) {
      const userItems: Items[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          userItems,
          loadingItems: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingItems: false }));
    }
  }
}

export interface UPDATE_INVOICE_Payload {
  refPath: string;
  invoice: Partial<Invoice>;
  notify?: boolean;
  checkData?: boolean;
}
export function* UPDATE_INVOICE(
  input: ReturnType<typeof actions.UPDATE_INVOICE>
) {
  yield put(actions.SET_STATE({ loadingSave: true }));
  const { invoice, notify } = input.payload;

  const result: ServiceReturn = yield call(updateInvoice, input.payload);

  if (result.data) {
    // update redux state invoice
    yield put(actions.UPDATE_INVOICE_STATE(invoice));
    // set success alert
    if (notify) {
      yield put(alertActions.SUCCESS("Invoice Updated"));
    }
    yield put(actions.SET_STATE({ loadingSave: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
  yield put(actions.SET_STATE({ loadingSave: false }));
}

export interface UPDATE_VOTE_Payload {
  vote: Partial<Invoice>;
  notify?: boolean;
}
export function* UPDATE_VOTE(input: ReturnType<typeof actions.UPDATE_VOTE>) {
  const { notify, vote } = input.payload;
  yield put(actions.SET_STATE({ loadingSave: true }));
  const result: ServiceReturn = yield call(updateVote, input.payload);

  if (result.data) {
    const votesResults: Record<
      string,
      {
        votes: Invoice[];
        loading: boolean;
      }
    > = yield select((state: RootState) => state.invoices.votesResults);
    const invoiceId = vote.invoiceId;
    const voteId = vote.id;
    if (invoiceId && voteId) {
      const votesResultsOnInvoiceId: Invoice[] = yield select(
        (state: RootState) => state.invoices.votesResults[invoiceId]?.votes
      );
      const updatedVotesResults = votesResultsOnInvoiceId.map((x) =>
        x.id === voteId ? { ...x, ...vote } : x
      );
      yield put(
        actions.SET_STATE({
          votesResults: {
            ...votesResults,
            [invoiceId]: { votes: updatedVotesResults, loading: false },
          },
        })
      );
    }

    if (notify) {
      yield put(alertActions.SUCCESS("Invoice Updated"));
    }
    yield put(actions.SET_STATE({ loadingSave: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
  yield put(actions.SET_STATE({ loadingSave: false }));
}

export interface DELETE_VOTES_Payload {
  invoiceRefPath: string;
  userId: string;
}
export function* DELETE_VOTES(input: ReturnType<typeof actions.DELETE_VOTES>) {
  const { invoiceRefPath, userId } = input.payload;

  const result: ServiceReturn = yield call(deleteVotes, invoiceRefPath, userId);

  if (result.data) {
    yield put(alertActions.SUCCESS("Vote Data Updated."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface CHECK_INVOICE_payload {
  invoice: CheckedInvoice | Invoice;
  notify?: boolean;
}
export function* CHECK_INVOICE(
  input: ReturnType<typeof actions.CHECK_INVOICE>
) {
  const { invoice, notify } = input.payload;

  const result: ServiceReturn = yield call(checkInvoice, invoice);

  if (result.data) {
    // set success alert
    if (notify) {
      yield put(actions.UPDATE_INVOICE_STATE(invoice as Invoice));
      yield put(alertActions.SUCCESS("Invoice and Votes have been updated"));
    } else {
      yield put(
        alertActions.SUCCESS(
          `Invoice is ${
            invoice.isChecked ? "checked" : "unchecked"
          } successfully`
        )
      );
    }
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_CHECK_INVOICES_payload {
  invoicesArray: CheckedInvoice[];
}
export function* BATCH_CHECK_INVOICES(
  input: ReturnType<typeof actions.BATCH_CHECK_INVOICES>
) {
  const { invoicesArray } = input.payload;

  const result: ServiceReturn = yield call(batchCheckInvoices, invoicesArray);

  if (result.data) {
    // set success alert
    yield put(alertActions.SUCCESS(`All invoices are checked successfully`));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface VOTE_INVOICE_Payload {
  invoice: Invoice;
  notify: boolean;
}
export function* VOTE_INVOICE(input: ReturnType<typeof actions.VOTE_INVOICE>) {
  const { invoice, notify } = input.payload;
  yield put(actions.SET_STATE({ loadingSave: true }));
  const maxVotes: number | undefined = yield select(
    (state: RootState) => state.settings.dataVotingConfig?.maxVotes
  );

  const result: ServiceReturn = yield call(voteInvoice, invoice, maxVotes);

  if (result.data) {
    // update redux state invoice
    yield put(actions.UPDATE_INVOICE_STATE(invoice));
    // set success alert
    if (notify) {
      yield put(alertActions.SUCCESS("Invoice Voted"));
    }
    yield put(actions.SET_STATE({ loadingSave: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(actions.SET_STATE({ loadingSave: false }));
  }
}

export interface CREATE_ITEM_Payload {
  item: Items;
  userId: string;
}

export function* CREATE_ITEM(input: ReturnType<typeof actions.CREATE_ITEM>) {
  const { item, userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingItemCreate: true,
    })
  );

  const result: ServiceReturn = yield call(addUserItems, item, userId);
  if (result.data) {
    yield put(
      actions.SET_STATE({ loadingItemCreate: false, newItemId: result.data })
    );
    yield put(alertActions.SUCCESS("Item Created"));
  } else {
    yield put(actions.SET_STATE({ loadingItemCreate: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_ITEM_Payload {
  item: Partial<Items>;
  userId: string;
}

export function* UPDATE_ITEM(input: ReturnType<typeof actions.UPDATE_ITEM>) {
  const { item, userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingItemUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(updateUserItems, item, userId);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingItemUpdate: false }));
    yield put(alertActions.SUCCESS(`Item Name Updated to: ${item.name}`));
  } else {
    yield put(actions.SET_STATE({ loadingItemUpdate: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface FILE_UPLOAD_Payload {
  userId: string;
  file: File;
}
export function* FILE_UPLOAD(input: ReturnType<typeof actions.FILE_UPLOAD>) {
  const { userId, file } = input.payload;

  yield put(
    actions.SET_STATE({
      isUploading: true,
    })
  );

  const result: ServiceReturn = yield call(fileUpload, userId, file);
  yield put(
    actions.SET_STATE({
      isUploading: false,
    })
  );

  if (result.data) {
    yield put(
      actions.SET_STATE({
        downloadURL: result.data,
      })
    );
  }
}

export interface ADD_DOWNLOAD_URL_Payload {
  id: string;
  userId: string;
  downloadURL: string;
}

export function* ADD_DOWNLOAD_URL(
  input: ReturnType<typeof actions.ADD_DOWNLOAD_URL>
) {
  const { id, userId, downloadURL } = input.payload;

  const result: ServiceReturn = yield call(
    addDownloadURL,
    id,
    userId,
    downloadURL
  );
  yield put(
    actions.SET_STATE({
      isUploading: true,
    })
  );

  if (result.data) {
    yield put(actions.SET_STATE({ isUploading: false, downloadURL: "" }));
    yield put(alertActions.SUCCESS(INVOICE.IMAGE_UPLOADED));
  } else {
    yield put(actions.SET_STATE({ downloadURL: "" }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface DELETE_IMAGE_Payload {
  id: string;
  userId: string;
  imageFile: string;
}
export function* DELETE_IMAGE(input: ReturnType<typeof actions.DELETE_IMAGE>) {
  const { id, userId, imageFile } = input.payload;

  const result: ServiceReturn = yield call(deleteImage, id, userId, imageFile);
  if (result.data) {
    yield put(alertActions.SUCCESS(INVOICE.IMAGE_DELETED));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface GET_OCR_DATA_Payload {
  userId: string;
  imageUrl: string;
}
export function* GET_OCR_DATA(input: ReturnType<typeof actions.GET_OCR_DATA>) {
  const { userId, imageUrl } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingOcrData: true,
    })
  );

  const result: ServiceReturn = yield call(getOcrData, userId, imageUrl);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        ocrData: result.data,
        loadingOcrData: false,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingOcrData: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export function* GET_DOCUMENT_AI_DATA(
  input: ReturnType<typeof actions.GET_DOCUMENT_AI_DATA>
) {
  const { userId, imageUrl } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingDocumentAIData: true,
    })
  );

  const result: ServiceReturn = yield call(getDocumentAIData, userId, imageUrl);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        documentAIData: result.data,
        loadingDocumentAIData: false,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingDocumentAIData: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_DOCUMENT_AI_DATA_Payload {
  data: DocumentAI;
}
export function* UPDATE_DOCUMENT_AI_DATA(
  input: ReturnType<typeof actions.UPDATE_DOCUMENT_AI_DATA>
) {
  const { data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingDocumentAIData: true,
    })
  );

  const result: ServiceReturn = yield call(updateDocumentAI, data);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        loadingDocumentAIData: false,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingDocumentAIData: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface SUBSCRIBE_TO_INVOICE_VOTES_payload {
  direction?: "next" | "previous";
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  firstDoc?: QueryDocumentSnapshot<DocumentData>;
  userId?: string;
  voterUserId?: string;
}
export function* SUBSCRIBE_TO_INVOICE_VOTES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_INVOICE_VOTES>
) {
  const { direction, lastDoc, firstDoc, userId, voterUserId } = input.payload;

  yield put(
    actions.SET_STATE({
      loading: true,
      votes: { data: [] },
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setInvoiceVotesListener,
    direction,
    lastDoc,
    firstDoc,
    userId,
    voterUserId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_INVOICE_VOTES);
    channel.close();
  });

  type ReturnedData = {
    data: InvoiceVote[];
    lastDoc?: QueryDocumentSnapshot<DocumentData>;
    firstDoc?: QueryDocumentSnapshot<DocumentData>;
  };

  try {
    while (true) {
      const {
        data: result,
        lastDoc,
        firstDoc,
      }: ReturnedData = yield take(channel);

      if (result) {
        yield put(
          actions.SET_STATE({
            loading: false,
            votes: { data: result, lastDoc, firstDoc },
          })
        );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loading: false,
        })
      );
    }
  }
}

export interface SUBSCRIBE_TO_USER_VOTES_RESULTS_Payload {
  userId: string;
  invoiceId: string;
}
export function* SUBSCRIBE_TO_USER_VOTES_RESULTS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_VOTES_RESULTS>
) {
  const votesResults: Record<
    string,
    {
      votes: Invoice[];
      loading: boolean;
    }
  > = yield select((state: RootState) => state.invoices.votesResults);
  yield put(
    actions.SET_STATE({
      votesResults: {
        ...votesResults,
        [input.payload.invoiceId]: { votes: [], loading: true },
      },
    })
  );
  const { userId, invoiceId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserVotesResultListener,
    userId,
    invoiceId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_VOTES_RESULTS);
    channel.close();
  });

  try {
    while (true) {
      const votes: Invoice[] = yield take(channel);

      yield put(
        actions.SET_STATE({
          votesResults: {
            ...votesResults,
            [invoiceId]: { votes: votes, loading: false },
          },
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          votesResults: {
            ...votesResults,
            [invoiceId]: {
              votes: votesResults.invoiceId?.votes ?? [],
              loading: false,
            },
          },
        })
      );
    }
  }
}
export interface SUBSCRIBE_TO_UNCHECKED_INVOICES_Payload {
  userId: string;
}
export function* SUBSCRIBE_TO_UNCHECKED_INVOICES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_UNCHECKED_INVOICES>
) {
  yield put(actions.SET_USER_STATUS_INVOICES({ loadingUnchecked: true }));
  const { userId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserStatusInvoiceListener,
    userId,
    false
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_UNCHECKED_INVOICES);
    yield put(
      actions.SET_STATE({
        userStatus: {} as UserStatusInvoices,
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const unchecked: Invoice[] = yield take(channel);

      yield put(
        actions.SET_USER_STATUS_INVOICES({ unchecked, loadingUnchecked: false })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_USER_STATUS_INVOICES({ loadingUnchecked: false }));
    }
  }
}

export interface SUBSCRIBE_TO_CHECKED_INVOICES_Payload {
  userId: string;
  maxInvoices?: number;
}
export function* SUBSCRIBE_TO_CHECKED_INVOICES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_CHECKED_INVOICES>
) {
  yield put(actions.SET_USER_STATUS_INVOICES({ loadingChecked: true }));
  const { userId, maxInvoices = 15 } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserStatusInvoiceListener,
    userId,
    true,
    maxInvoices
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_CHECKED_INVOICES);
    yield put(actions.SET_STATE({ userStatus: {} as UserStatusInvoices }));
    channel.close();
  });

  try {
    while (true) {
      const checked: Invoice[] = yield take(channel);

      yield put(
        actions.SET_USER_STATUS_INVOICES({ checked, loadingChecked: false })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_USER_STATUS_INVOICES({ loadingChecked: false }));
    }
  }
}

export interface UPLOAD_AND_CREATE_INVOICE_Payload {
  userId: string;
  files: File[];
}
export function* UPLOAD_AND_CREATE_INVOICE(
  input: ReturnType<typeof actions.UPLOAD_AND_CREATE_INVOICE>
) {
  yield put(
    actions.SET_STATE({
      loadingUploadAndCreateInvoice: true,
    })
  );
  const { userId, files } = input.payload;

  const result: ServiceReturn = yield call(
    uploadAndCreateInvoice,
    userId,
    files
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Invoice created."));

    yield put(
      actions.SET_STATE({
        loadingUploadAndCreateInvoice: false,
      })
    );
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(
      actions.SET_STATE({
        loadingUploadAndCreateInvoice: false,
      })
    );
  }
}

export interface GET_RESOLVING_INVOICE_METRICS_Payload {
  dateFrom: Date;
  dateTo: Date;
}

function* SUBSCRIBE_TO_RESOLVING_INVOICE_METRICS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_RESOLVING_INVOICE_METRICS>
) {
  const { dateFrom, dateTo } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingInvoiceResolvingMetrics: true,
      invoiceResolvingMetrics: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setInvoiceResolvingMetricsListener,
    dateFrom,
    dateTo
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_RESOLVING_INVOICE_METRICS);
    channel.close();
    yield put(
      actions.SET_STATE({
        invoiceResolvingMetrics: [],
      })
    );
  });

  try {
    while (true) {
      const data: InvoiceResolvingMetric[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          invoiceResolvingMetrics: data,
          loadingInvoiceResolvingMetrics: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingInvoiceResolvingMetrics: false }));
    }
  }
}

function* SUBSCRIBE_TO_CHECKING_INVOICE_METRICS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_CHECKING_INVOICE_METRICS>
) {
  const { dateFrom, dateTo } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingInvoiceCheckingMetrics: true,
      invoiceCheckingMetrics: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setInvoiceCheckingMetricsListener,
    dateFrom,
    dateTo
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_CHECKING_INVOICE_METRICS);
    channel.close();
    yield put(
      actions.SET_STATE({
        invoiceCheckingMetrics: [],
      })
    );
  });

  try {
    while (true) {
      const data: InvoiceCheckingMetric[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          invoiceCheckingMetrics: data,
          loadingInvoiceCheckingMetrics: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingInvoiceResolvingMetrics: false }));
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.SUBSCRIBE_TO_USER_VOTES_RESULTS,
      SUBSCRIBE_TO_USER_VOTES_RESULTS
    ),
    takeLatest(actions.UPDATE_VOTE, UPDATE_VOTE),
    takeLatest(actions.UPDATE_INVOICE, UPDATE_INVOICE),
    takeLatest(actions.CHECK_INVOICE, CHECK_INVOICE),
    takeLatest(actions.BATCH_CHECK_INVOICES, BATCH_CHECK_INVOICES),
    takeLatest(actions.VOTE_INVOICE, VOTE_INVOICE),
    takeLatest(actions.CREATE_ITEM, CREATE_ITEM),
    takeLatest(actions.UPDATE_ITEM, UPDATE_ITEM),
    takeLatest(actions.SUBSCRIBE_TO_FEES, SUBSCRIBE_TO_FEES),
    takeLatest(actions.SUBSCRIBE_TO_USER_ITEMS, SUBSCRIBE_TO_USER_ITEMS),
    takeLatest(actions.SUBSCRIBE_TO_USER_INVOICE, SUBSCRIBE_TO_USER_INVOICE),
    takeLatest(
      actions.SUBSCRIBE_TO_USER_INVOICE_VOTES,
      SUBSCRIBE_TO_USER_INVOICE_VOTES
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_USER_INVOICE_TABLE,
      SUBSCRIBE_TO_USER_INVOICE_TABLE
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_USER_ITEMS_TABLE,
      SUBSCRIBE_TO_USER_ITEMS_TABLE
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_USER_FEES_TABLE,
      SUBSCRIBE_TO_USER_FEES_TABLE
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_TABLE_VIEW_INVOICES,
      SUBSCRIBE_TO_TABLE_VIEW_INVOICES
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_UNCHECKED_INVOICES,
      SUBSCRIBE_TO_UNCHECKED_INVOICES
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_CHECKED_INVOICES,
      SUBSCRIBE_TO_CHECKED_INVOICES
    ),
    takeLatest(actions.FILE_UPLOAD, FILE_UPLOAD),
    takeLatest(actions.ADD_DOWNLOAD_URL, ADD_DOWNLOAD_URL),
    takeLatest(actions.DELETE_IMAGE, DELETE_IMAGE),
    takeLatest(actions.GET_OCR_DATA, GET_OCR_DATA),
    takeLatest(actions.GET_DOCUMENT_AI_DATA, GET_DOCUMENT_AI_DATA),
    takeLatest(actions.UPDATE_DOCUMENT_AI_DATA, UPDATE_DOCUMENT_AI_DATA),
    takeLatest(actions.SUBSCRIBE_TO_INVOICE_VOTES, SUBSCRIBE_TO_INVOICE_VOTES),
    takeLatest(actions.DELETE_VOTES, DELETE_VOTES),
    takeLatest(actions.UPLOAD_AND_CREATE_INVOICE, UPLOAD_AND_CREATE_INVOICE),
    takeLatest(actions.SUBSCRIBE_TO_USER_ORDERS, SUBSCRIBE_TO_USER_ORDERS),
    takeLatest(
      actions.SUBSCRIBE_TO_RESOLVING_INVOICE_METRICS,
      SUBSCRIBE_TO_RESOLVING_INVOICE_METRICS
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_CHECKING_INVOICE_METRICS,
      SUBSCRIBE_TO_CHECKING_INVOICE_METRICS
    ),
  ]);
}
