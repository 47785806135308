import { DocumentData } from "firebase/firestore";
import store from "redux/store";
import { RawStateChange, User } from "./types";

const processCountsData = (counts: any[]) => {
  const convertChangedAt = (stateChange: RawStateChange) => {
    if (!stateChange.changedAt) return stateChange;
    else
      return {
        ...stateChange,
        changedAt: stateChange.changedAt?.toDate(),
      };
  };

  const convertCreatedUpdatedAt = (count: any) => ({
    ...count,
    createdAt: count.createdAt?.toDate(),
    updatedAt: count.updatedAt?.toDate(),
  });

  const convertStateChanges = (count: any) => {
    if (!count.stateChanges) return convertCreatedUpdatedAt(count);
    else
      return convertCreatedUpdatedAt({
        ...count,
        stateChanges: count.stateChanges?.map(convertChangedAt),
      });
  };

  const cleanConfirmedStatus = (count: any) => {
    if (!count.confirmedStatus) return count;
    return {
      ...count,
      confirmedStatus: {
        ...count.confirmedStatus,
        cost: count.confirmedStatus.cost || false,
        ioh: count.confirmedStatus.ioh || false,
        priceChanges: count.confirmedStatus.priceChanges || false,
        salesMatch: count.confirmedStatus.salesMatch || false,
      },
    };
  };

  return counts?.map((count) => ({
    ...count,
    count: cleanConfirmedStatus(convertStateChanges(count.count)),
  }));
};

export const getUsersWithCounts = (
  users: User[],
  counts: DocumentData[],
  accessLevels: string[]
): User[] => {
  const modifiedUsers = users.map((doc) => ({
    ...doc,
    organizationName: doc.organizationId
      ? users.find((u) => u.id === doc.organizationId)?.name
      : undefined,
  }));
  // Convert all Firestore Timestamps and clean confirmedStatus
  const countsWithoutTimestamp = processCountsData(counts);

  return modifiedUsers.map((user) => {
    const userCounts = countsWithoutTimestamp.filter(
      (value) => value.userId === user.id
    );
    const count = userCounts.length > 0 ? userCounts[0] : {};
    const accessLevelName = accessLevels.find(
      (_, i: number) => i === user.accessLevel
    );
    return {
      ...user,
      counts: userCounts ?? [],
      count: count?.count ?? {},
      accessLevelName: accessLevelName ?? "",
    };
  });
};

/**
 * @param {string} userId - The id of the user
 * @returns {string} The currency of the user
 */
export function getUserCurrency(userId: string): string {
  const users = store.getState().users.users;
  const user = users.find((x) => x.id === userId);
  return user?.currencyShort ?? "kr";
}

/**
 * @param {string} userId - The id of the user
 * @returns {string} The currency of the user
 */
export function getUserLongCurrency(userId: string): string {
  const users = store.getState().users.users;
  const user = users.find((x) => x.id === userId);
  return user?.currencyLong ?? "NOK";
}

export function getUserLocale(userId: string): string {
  const users = store.getState().users.users;
  const user = users.find((x) => x.id === userId);
  return user?.locale ?? "no";
}

export const getUserName = (userId: string): string => {
  const users = store.getState().users.users;
  const data = users.find((user) => user.id === userId);
  return data?.name ?? data?.email ?? userId;
};

export const getAPVendor = (userId: string): string => {
  const users = store.getState().users.users;
  return users.find((user) => user.id === userId)?.apVendorName ?? userId;
};

export const isUserDemoAccount = (userId: string): boolean => {
  const users = store.getState().users.users;
  return users.find((user) => user.id === userId)?.demo ?? false;
};

export const getApUploadRecipients = (userId: string): string[] => {
  const users = store.getState().users.users;
  return users.find((user) => user.id === userId)?.apUploadRecipients ?? [];
};

export const getOrganizationName = (userId: string): string => {
  const users = store.getState().users.users;
  return users.find((user) => user.id === userId)?.organizationName ?? userId;
};
