import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import {
  APTransactionsState,
  ClassificationCount,
  UserStatusTransactions,
} from "./types";

const initialState: APTransactionsState = {
  image: [],
  loadingImages: false,
  loadingFirstImages: false,
  loadingUpload: false,
  userStatus: {} as UserStatusTransactions,
  loadingApMetrics: false,
  apMetrics: [],
  loadingUploadAndCreateTransactions: false,
  todayApMetric: {} as ClassificationCount,
  tableViewTransactions: [],
  tableViewLoading: false,
  getImageProgress: 0,
  rowUploadInvoiceLoading: {},
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addCase(actions.ADD_IMAGE, (state, action) => {
    // find image by payload.id
    const image = state.image.find((image) => image.id === action.payload?.id);
    // if image is found, replace it with new image
    if (image) {
      const newImage = { ...image, ...action.payload };
      return {
        ...state,
        image: state.image.map((image) =>
          image.id === newImage.id ? newImage : image
        ),
      };
    }
    // if image is not found, add new image to image array
    return {
      ...state,
      image: [...state.image, action.payload],
    };
  });

  builder.addCase(actions.SET_USER_STATUS_TRANSACTIONS, (state, action) => ({
    ...state,
    userStatus: {
      ...state.userStatus,
      ...action.payload,
    },
  }));
  builder.addDefaultCase((state) => state);
});
