import { DocumentData, DocumentReference, Timestamp } from "firebase/firestore";

export type APTransactionsState = {
  image: TransactionImage[];
  loadingImages: boolean;
  loadingFirstImages: boolean;
  loadingUpload: boolean;
  userStatus: UserStatusTransactions;
  apMetrics: ClassificationCount[];
  loadingApMetrics: boolean;
  loadingUploadAndCreateTransactions: boolean;
  todayApMetric: ClassificationCount;
  tableViewTransactions: APTransaction[];
  tableViewLoading: boolean;
  getImageProgress: number;
  rowUploadInvoiceLoading:Record<string, boolean>
};

export type UserStatusTransactions = {
  unresolved: APTransaction[];
  resolved: APTransaction[];
  loadingUnresolved: boolean;
  loadingResolved: boolean;
};

/**
 * AP Transaction Image
 * @category AP Transactions
 */
export type TransactionImage = {
  /** Image ID */
  id: string;
  /** Image loading status */
  loading: boolean;
  /** Image data */
  data: APImageData[] | null;
};

export type TypesenseOCRData = {
  fileName: string;
  id: string;
  itemId: string;
  transactionId: string;
  userId: string;
  wordCollection: string[];
};

/**
 * AP Transaction Image Data
 * @category AP Transactions
 */
export interface APImageData {
  /** Image file source URL */
  source: string;
  /** Page of the image */
  page: number;
  /** URL of the image */
  url: string;
  /** Error that occurs on conversion process */
  error?: Error;
  /** File path */
  filePath: string;
}

export interface APImageDataUpload extends APImageData {
  /** Indicates which invoice the image belongs to */
  invoiceCounter: number;
}

export class APTransaction {
  id: string;
  ref: DocumentReference;

  amount: number;
  createdAt?: Timestamp;
  files?: string[];
  invoiceNumber: string;
  invoiceId?: string;
  isSensitive?: boolean;
  path?: string;
  resolved: boolean;
  transactionHeader: {
    transactionSource: {
      description: string | number;
    };
  };
  transactionType: string;
  updatedAt?: Timestamp;
  userId: string;
  vendor: string;
  resolvedBy?: string;
  oldestDate?: Timestamp;
  oldestDatestring?: string;
  selectedImages?: APImageData[];
  deleted: boolean;
  hasBrokenImages?: boolean;
  invoiceIds?: string[];
  isGeminiListUpdated?: boolean;
  thumbnails?: Record<string, string[]>;
  supplier: string;
  outcome: string;
  classificationSuccess?: boolean;
  classificationPrediction?: number;

  constructor(id: string, ref: DocumentReference, data: DocumentData) {
    this.id = id;
    this.ref = ref;
    this.amount = data.amount;
    this.createdAt = data.createdAt;
    this.files = data.files;
    this.invoiceNumber = data.invoiceNumber;
    this.invoiceId = data.invoiceId;
    this.isSensitive = data.isSensitive ?? false;
    this.path = data.path;
    this.resolved = data.resolved ?? false;
    this.transactionHeader = data.transactionHeader;
    this.transactionType = data.transactionType;
    this.updatedAt = data.updatedAt;
    this.userId = data.userId;
    this.vendor = data.vendor;
    this.resolvedBy = data.resolvedBy;
    this.oldestDate = data.oldestDate;
    this.oldestDatestring = data.oldestDatestring;
    this.selectedImages = data.selectedImages;
    this.deleted = data.deleted ?? false;
    this.hasBrokenImages = data.hasBrokenImages ?? false;
    this.invoiceIds = data.invoiceIds ?? [];
    this.isGeminiListUpdated = data.isGeminiListUpdated;
    this.thumbnails = data.thumbnails;
    this.supplier = data.geminiResponse?.length
      ? data.geminiResponse.find(
          (x: GeminiResponse) => x.response?.supplier_name
        )?.response?.supplier_name ?? ""
      : "";
    this.outcome =
      data.outcome ??
      getTransactionOutcome(
        data.resolved,
        data.resolvedBy,
        data.classificationSuccess,
        data.classificationPrediction,
        data.selectedImages
      );
  }
}

type GeminiResponse = {
  response?: any;
  filePath: string;
  pageIndex: number;
};

export type ClassificationCount = {
  totalAutoClassifiedTransactions: number;
  failedClassification: number;
  classifiedAsInvoice: number;
  classifiedAsNotInvoice: number;
  correctlyClassifiedAsInvoice: number;
  correctlyClassifiedAsNotInvoice: number;
  edgeCases?: string[];
  usersHaveNoTransactionInOneWeek?: string[];
};

export type ClassificationCountData = ClassificationCount & {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const getTransactionOutcome = (
  resolved: boolean,
  resolvedBy: string,
  classificationSuccess: boolean,
  classificationPrediction: number,
  selectedImages: APImageData[]
) => {
  if (resolved) {
    if (resolvedBy === "bot") {
      if (classificationSuccess && (classificationPrediction ?? 0) > 0) {
        return "Bot Created Invoices";
      } else if (classificationSuccess && classificationPrediction === 0) {
        return "Bot Not Invoice";
      } else if (!classificationSuccess) {
        return "Bot Not Sure";
      }
    } else if (resolvedBy !== "bot") {
      if (selectedImages && selectedImages.length > 0) {
        return "Human Created Invoice";
      } else {
        return "Human Not Invoice";
      }
    }
  } else {
    return "Unresolved";
  }
};
