import {
  Invoice,
  InvoiceItem,
  InvoiceItemFirebase,
  mapInvoiceItemToInvoiceItemFirebase,
} from "redux/invoice/types";
import { Selection } from "redux/ocr/types";
import { selections } from "../invoice-ocr/constants";
import { ITEM_TAGS } from "../tag.helpers";

export type ResolvedSelection = Selection & { isResolvedItem: boolean };

export const filterByItemTags = (
  data: Invoice,
  item: InvoiceItem | undefined,
  itemTags: string[]
) => {
  if (!item) return false;
  if (itemTags.length > 0) {
    if (itemTags.includes("newAlias")) {
      return (
        ITEM_TAGS.some((tag) => {
          if (itemTags.some((x) => x === tag.name)) return tag.filterFn(item);
          else return false;
        }) || Object.keys(data.newAliases).includes(item.id)
      );
    } else {
      return ITEM_TAGS.some((tag) => {
        if (itemTags.some((x) => x === tag.name)) return tag.filterFn(item);
        else return false;
      });
    }
  } else {
    return true;
  }
};

export const mergeDuplicateItems = (itemsList: InvoiceItem[]) => {
  const items = {} as Record<string, InvoiceItemFirebase>;
  const isItemValid = (v: InvoiceItem) =>
    !v.isOpenItem && v.id !== null && v.id !== "";

  const aggregateItemQuantities = (
    itemIdToProcess: string,
    isForSponsoredItem: boolean
  ) => {
    const targetItems = itemsList.filter(
      (x) =>
        x.id === itemIdToProcess &&
        (isForSponsoredItem ? x.isSponsored : !x.isSponsored)
    );

    const sumQuantity = targetItems.reduce((x, y) => x + y.quantity, 0);

    const item = { ...targetItems[0] };
    item.quantity = sumQuantity;
    if (!isForSponsoredItem) {
      const sumCost = targetItems.reduce((x, y) => x + y.cost, 0);
      item.cost = sumCost / targetItems.length;
    } else {
      item.cost = item.oldCost;
    }

    return item;
  };

  const uniqueValues = new Set(
    itemsList.filter((v) => isItemValid(v) && !v.isSponsored).map((v) => v.id)
  );

  for (const itemId of uniqueValues) {
    const item = aggregateItemQuantities(itemId, false);

    items[itemId] = mapInvoiceItemToInvoiceItemFirebase(item);
  }

  const uniqueValuesSponsored = new Set(
    itemsList.filter((v) => isItemValid(v) && v.isSponsored).map((v) => v.id)
  );

  for (const itemId of uniqueValuesSponsored) {
    const item = aggregateItemQuantities(itemId, true);

    if (items[itemId]) {
      items[itemId].quantity += item.quantity;
    } else {
      items[itemId] = mapInvoiceItemToInvoiceItemFirebase(item);
    }
    items[itemId].isSponsored = true;
  }

  return items;
};

export function getItemTags(items: InvoiceItem[]) {
  return ITEM_TAGS.reduce(
    (acc, tag) => {
      acc[tag.name] = items?.filter(tag.filterFn) || [];
      return acc;
    },
    {} as Record<string, InvoiceItem[]>
  );
}

export function findNearestOCRClick(
  ocrClick: Selection[],
  selection: Selection
): Selection | undefined {
  const ocrClickFiltered = ocrClick.filter((ocr) => {
    const selectedIndex =
      ocr.selectedItemIndex !== undefined ? ocr.selectedItemIndex : null;
    const isItemOrFees =
      ocr.selectedType === selections.Item ||
      ocr.selectedType === selections.AddOpenItem ||
      ocr.selectedType === selections.AddDiverseItem;
    return (
      isItemOrFees &&
      selectedIndex !== null &&
      ocr.imageUrl === selection.imageUrl
    );
  });
  const selectionY = Object.values(selection.boundingPoly[0])[0].y;

  interface Score {
    diff: number;
    occurrences: number;
    index: number;
  }
  let minDiff = Number.MAX_SAFE_INTEGER;
  const scores: Score[] = ocrClickFiltered.map((ocr, index) => {
    const boundingPoly = ocr.boundingPoly;

    // Get all Y vertices in the boundingPoly
    const yVertices = Object.values(boundingPoly)
      .map((arr) => arr.map((vertex) => vertex.y))
      .flat();

    // Get the difference between selectionY and all Y vertices
    const differences = yVertices.map((y) => Math.abs(y - selectionY));

    // Get the lowest difference
    const min = Math.min(...differences);

    // Get the number of occurrences of the lowest difference
    const occurrences = differences.filter((diff) => diff === min).length;

    // Set minDiff to the lowest difference
    if (min < minDiff) minDiff = min;
    return { diff: min, occurrences, index };
  });

  // Find items with lowest diff
  const lowestDiffItems = scores.filter((total) => total.diff === minDiff);

  // Find highest occurrence from items with lowest diff
  const highestOccurrence = Math.max(
    ...lowestDiffItems.map((total) => total.occurrences)
  );

  // Find items with lowest diff and highest occurrences
  const bestItem = lowestDiffItems.filter(
    (total) => total.occurrences === highestOccurrence
  );
  return ocrClickFiltered[bestItem[0]?.index];
}
export function calculateTotals(
  items: InvoiceItem[] | undefined,
  foodTotal: number
) {
  let gt = 0;
  const feeTotal = { delivery: 0, caution: 0, rent: 0, total: 0 };
  let drinkTotal = 0;
  let matTotal = 0;
  let diverseTotal = 0;
  let openItemTotal = 0;
  let fiveteenMvaTotal = 0;
  let twentyFiveMvaTotal = 0;

  for (const item of items ?? []) {
    if (item.isDuplicate || item.isSponsored) continue;
    if (typeof item.total === "number") {
      const lowerCaseType = item.type?.toLowerCase();

      gt += item.total;

      if (
        lowerCaseType === "fee" &&
        item.name
          ?.split("-")
          .map((phrase) => phrase.trim())[0]
          .toLowerCase() !== "diverse item"
      ) {
        if (item.taxDeduction && item.taxDeduction !== 0) {
          if (item.taxDeduction === 15) fiveteenMvaTotal += item.total;
          else if (item.taxDeduction === 25) twentyFiveMvaTotal += item.total;
        } else {
          feeTotal.total += item.total;
        }

        if (
          item.name
            ?.split("-")
            .map((phrase) => phrase.trim())[0]
            .toLowerCase() === "delivery"
        )
          feeTotal.delivery += item.total;
        else if (
          item.name
            ?.split("-")
            .map((phrase) => phrase.trim())[0]
            .toLowerCase() === "caution"
        )
          feeTotal.caution += item.total;
        else if (
          item.name
            ?.split("-")
            .map((phrase) => phrase.trim())[0]
            .toLowerCase() === "rent"
        )
          feeTotal.rent += item.total;
      } else if (
        lowerCaseType === "mat" ||
        lowerCaseType === "hrana" ||
        lowerCaseType === "food"
      ) {
        matTotal += item.total;
        fiveteenMvaTotal += item.total;
      } else if (
        lowerCaseType === "alkoholfritt" ||
        lowerCaseType === "bezalkoholno piće" ||
        lowerCaseType === "alcohol free"
      ) {
        drinkTotal += item.total;
        fiveteenMvaTotal += item.total;
      } else if (
        lowerCaseType === "diverse" ||
        lowerCaseType === "ostalo" ||
        lowerCaseType === "various"
      ) {
        diverseTotal += item.total;
      } else if (
        lowerCaseType === "tobakksvarer" ||
        lowerCaseType === "duhanski proizvod" ||
        lowerCaseType === "tobacco"
      ) {
        diverseTotal += item.total;
        twentyFiveMvaTotal += item.total;
      } else if (
        lowerCaseType === "fee" &&
        item.name
          ?.split("-")
          .map((phrase) => phrase.trim())[0]
          .toLowerCase() === "diverse item"
      ) {
        if (item.taxDeduction && item.taxDeduction !== 0) {
          if (item.taxDeduction === 15) fiveteenMvaTotal += item.total;
          else if (item.taxDeduction === 25) twentyFiveMvaTotal += item.total;
        } else {
          twentyFiveMvaTotal += item.total;
        }
        diverseTotal += item.total;
      } else if (
        [
          "alkoholsvak",
          "brennevin",
          "øl",
          "vin",
          "cider",
          "rtd & fab",
          "likører",
          "sake",
          "starkvin",
          "starkøl",
          "taptails",
        ].includes(lowerCaseType) ||
        [
          "slabo alkoholno piće",
          "žestoko piće",
          "pivo",
          "jako pivo",
          "jako vino",
          "liker",
          "vino",
        ].includes(lowerCaseType) ||
        [
          "low alcohol",
          "spirits",
          "beer",
          "wine",
          "cider",
          "rtd & fab",
          "liqueur",
          "sake",
          "fortified wine",
          "strong beer",
        ].includes(lowerCaseType)
      ) {
        drinkTotal += item.total;
        twentyFiveMvaTotal += item.total;
      } else if (item.isOpenItem) openItemTotal += item.total;
    }
  }

  const checkFoodTotal = isNaN(foodTotal) || foodTotal === undefined;
  gt += checkFoodTotal ? 0 : +foodTotal;
  matTotal += checkFoodTotal ? 0 : +foodTotal;
  fiveteenMvaTotal += checkFoodTotal ? 0 : +foodTotal;

  return {
    grandTotal: +gt.toFixed(2),
    feeTotal: {
      delivery: +feeTotal.delivery.toFixed(2),
      caution: +feeTotal.caution.toFixed(2),
      rent: +feeTotal.rent.toFixed(2),
      total: +feeTotal.total.toFixed(2),
    },
    fiveteenMva: +fiveteenMvaTotal.toFixed(2),
    twentyFiveMva: +twentyFiveMvaTotal.toFixed(2),
    matFoodTotal: +matTotal.toFixed(2),
    diverseTotal: +diverseTotal.toFixed(2),
    drinkTotal: +drinkTotal.toFixed(2),
    openItemTotal: +openItemTotal.toFixed(2),
  };
}

export function getItemClicksOcr(
  ocrSelections: Selection[] | undefined,
  items: InvoiceItem[]
) {
  const returnedClicks: ResolvedSelection[] = [];
  if (!ocrSelections || ocrSelections.length === 0) return [];
  const isSomeMatchSelectedUUID = items.some((item) =>
    ocrSelections.find((c) => c.selectedUuid === item.uuid)
  );
  for (const item of items) {
    let clicks: ResolvedSelection[] = [];
    clicks = ocrClicksFilteredItem(
      ocrSelections,
      item,
      isSomeMatchSelectedUUID
    ).map((click) => {
      return { ...click, isResolvedItem: Boolean(item.id && !item.isOpenItem) };
    });

    returnedClicks.push(...clicks);
  }

  return returnedClicks;
}

export function ocrClicksFilteredItem(
  ocrSelections: Selection[],
  item: InvoiceItem,
  isSomeMatchSelectedUUID: boolean
) {
  const returnedClicks = ocrSelections.filter((c: Selection) => {
    const filteredUuidExist =
      item.uuid === c?.selectedUuid ||
      item.allUuidFromVotes?.includes(c?.selectedUuid || "");

    // If timestamp is after June 13, 2024 (last commit to assign uuid to selection) use stricter condition
    if (
      c.timestamp &&
      c.timestamp > new Date("2024-06-13") &&
      isSomeMatchSelectedUUID
    ) {
      return filteredUuidExist
        ? filteredUuidExist
        : c?.selectedItem === item.id && c?.selectedItemIndex === item.index;
    } else {
      // c?.selectedItem === item.id || c?.selectedItemIndex === item.index is condition for old input data before using uuid,
      // we can remove this condition after all new input data has uuid (maybe in 4 or 5 months after this commit date)
      return filteredUuidExist
        ? filteredUuidExist
        : c?.selectedItem === item.id && c?.selectedItemIndex === item.index
          ? c?.selectedItem === item.id && c?.selectedItemIndex === item.index
          : c?.selectedItem === item.id || c?.selectedItemIndex === item.index;
    }
  });
  return returnedClicks;
}

export function reorder(
  list: InvoiceItem[],
  startIndex: number,
  endIndex: number
): InvoiceItem[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function compareNumbersWithMargin(
  a: number,
  b: number,
  margin: number
): boolean {
  return Math.abs(a - b) <= margin;
}

export const isValidDateString = (dateString: string): boolean => {
  return !isNaN(Date.parse(dateString));
};

export const serializeOcrClicks = (
  ocrClicks: Selection[] | undefined
): Selection[] | undefined => {
  return ocrClicks?.map((click) => {
    const { ref } = click;

    if (ref) {
      const refPath = ref.path;
      const dataWithoutRef = { ...click, refPath: undefined };
      delete dataWithoutRef.ref;
      return { ...dataWithoutRef, refPath };
    } else {
      return click;
    }
  });
};

export const deepReplaceNaN = <T extends Record<string, any>>(obj: T): T => {
  const replacer = (_: string, value: unknown) => {
    if (typeof value === "number" && isNaN(value)) {
      return null;
    }
    return value;
  };

  return JSON.parse(JSON.stringify(obj, replacer));
};

export function findMultipliersInText(input: string): number | null {
  const specificNumbers = [6, 12, 24];
  const foundNumbers = input.match(/\d+/g)?.map(Number) || [];
  const filteredNumbers = foundNumbers.filter((number) =>
    specificNumbers.includes(number)
  );
  if (filteredNumbers.length === 1) {
    return filteredNumbers[0];
  } else {
    return filteredNumbers[filteredNumbers.length - 1] ?? null;
  }
}
