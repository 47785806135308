import { Icon, Tooltip } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { JSX, useMemo, useRef } from "react";
import { EditAttributeDialogRef } from "redux/types";
import EditAttributeDialog from "../edit-attribute-dialog";
import style from "./index.module.scss";

type Props = {
  hoveredValue: {
    key: string;
    val: string;
  };

  datum: any;

  col: any;

  getFieldValue: any;
  module: string;
  attrMap: Map<string, string>;
  thumbnailsRenderer?: (thumbnailsData: any, dataId: any) => JSX.Element;
};

const TableCell = ({
  hoveredValue,
  datum,
  col,
  getFieldValue,
  module,
  attrMap,
  thumbnailsRenderer,
}: Props) => {
  const countsEditableFields = [
    "state",
    "deleted",
    "dismissed",
    "startTime",
    "endTime",
  ];

  const dialogRef = useRef<EditAttributeDialogRef>(null);
  const fieldValue = useMemo(
    () => getFieldValue(col.name, datum[col.name]),
    [col.name, datum, getFieldValue]
  );

  const dateTimeFormat = () => {
    return (
      datum[col.name]?.toDate().toString().split(" ").splice(0, 5).join(" ") ||
      ""
    );
  };

  return (
    <>
      <div
        className={cn(
          hoveredValue.key === col.name &&
            hoveredValue.val === datum[col.name] &&
            typeof fieldValue === "string" &&
            style.hovered,
          style.row_value
        )}
        style={{
          cursor:
            module === "ap-transactions" && col.name === "thumbnails"
              ? undefined
              : (module !== "counts" &&
                    module !== "invoices-table-view" &&
                    module !== "ap-transactions" &&
                    module !== "integration-monitor") ||
                  (module === "counts" &&
                    countsEditableFields.includes(col.name))
                ? "pointer"
                : "not-allowed",
        }}
        onClick={() => {
          if (
            (module !== "counts" &&
              module !== "invoices-table-view" &&
              module !== "ap-transactions" &&
              module !== "integration-monitor") ||
            (module === "counts" && countsEditableFields.includes(col.name))
          ) {
            if (dialogRef.current) {
              dialogRef.current.handleOpenDialog();
            }
          }
        }}
      >
        {module === "ap-transactions" &&
        col.name === "thumbnails" &&
        thumbnailsRenderer ? (
          thumbnailsRenderer(datum[col.name], datum["id"])
        ) : (
          <span>
             {(typeof fieldValue === "string" && fieldValue.length > 50) ||
          col.name === "createdAt" ? (
            <Tooltip
              minimal
              content={`${
                col.name === "createdAt"
                  ? dateTimeFormat()
                  : fieldValue.slice(0, 200) + "..."
              }`}
            >
              {col.name === "createdAt"
                ? fieldValue
                : fieldValue.slice(0, 50) + "..."}
              </Tooltip>
            ) : (
              fieldValue
            )}
            <Icon
              icon="edit"
              className={style.edit_icon}
              style={{
                opacity:
                  hoveredValue.key === col.name &&
                  hoveredValue.val === datum[col.name] &&
                  typeof fieldValue === "string"
                    ? "100%"
                    : "0%",
              }}
            />
          </span>
        )}
      </div>
      <EditAttributeDialog
        ref={dialogRef}
        attrMap={attrMap}
        attributeTarget={col.name}
        attributeValue={datum[col.name]}
        id={datum.id}
        module={module}
        countRef={datum.ref}
      />
    </>
  );
};

export default TableCell;
