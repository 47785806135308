import {
  DocumentData,
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { IssueLog } from "redux/counts/types";
import { ActiveCount, IssueLogTagSuggestion } from "redux/users/types";
import { checkCounter } from "services/counter";
import { db, functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { SERVER_COUNTS } from "utils/constants";

export async function updateCountIsDone(
  userId: string,
  count: ActiveCount | undefined,
  destId: string
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!count) {
    return { data: null, error: "You need to include a count" };
  }

  const countRef = doc(db, `users/${userId}/counts/${count.countId}`);

  const oldData = await getDoc(countRef).then((doc) => {
    return doc.data();
  });

  const newCount = {
    ...oldData,
    isDone: destId === "done" ? true : false,
  };

  return await updateDoc(countRef, newCount)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateCount(
  countRef: DocumentReference,
  data: DocumentData
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(countRef, data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function batchUpdateCounts(
  countsArray: string[],
  userId: string,
  data: DocumentData
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, erro: SERVER_COUNTS.ERROR_MAX_COUNT };

  const batch = writeBatch(db);
  countsArray.forEach(async (countId) => {
    if (!db) return;
    const countRef = doc(db, "users", userId, "counts", countId);
    batch.update(countRef, {
      [data.attr]: data.value,
    });
  });

  return batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function createIssue(
  title: string,
  description: string,
  tags: IssueLogTagSuggestion[],
  createdBy: string,
  userId: string,
  countId: string
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, erro: SERVER_COUNTS.ERROR_MAX_COUNT };

  const issueCollectionRef = collection(db, `users/${userId}/issueLogs`);
  return await addDoc(issueCollectionRef, {
    title,
    description,
    tags,
    status: "unresolved",
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy,
    countId,
    deleted: false,
  })
    .then((docRef) => {
      return { data: docRef, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateIssueLog(
  userId: string,
  logId: string,
  updatedBy: string,
  newState: Partial<IssueLog>
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, erro: SERVER_COUNTS.ERROR_MAX_COUNT };

  const issueRef = doc(db, `users/${userId}/issueLogs/${logId}`);
  return await updateDoc(issueRef, {
    ...newState,
    updatedBy,
    updatedAt: new Date(),
  })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

interface ShareCostVarianceReportData {
  userId: string;
  countId: string;
  previousCountId: string;
  type: "COST_AND_VARIANCE_DOWNLOAD" | "COST_AND_VARIANCE_PREVIEW";
  periodLabel: string;
}

export async function getCnvReportLink(
  userId: string,
  countId: string,
  periodLabel: string,
  prevCountId?: string
) {
  if (!db) return null;
  const q = query(
    collection(db, "countReports"),
    where("userId", "==", userId),
    where("countId", "==", countId),
    where("type", "==", "COST_AND_VARIANCE_PREVIEW"),
    limit(1)
  );

  const querySnapshot = await getDocs(q);
  let path = "";
  if (querySnapshot.size) {
    path = querySnapshot.docs[0].data().link;
  } else {
    if (!functions || !prevCountId) {
      return null;
    }

    const shareCostVarianceReport = httpsCallable<
      ShareCostVarianceReportData,
      string
    >(functions, CALLABLE_FUNCTIONS.shareCostVarianceReport);

    const response = await shareCostVarianceReport({
      userId,
      countId,
      previousCountId: prevCountId,
      type: "COST_AND_VARIANCE_PREVIEW",
      periodLabel,
    });

    path = response.data;
  }

  return `https://web.stockifi.io${path}`;
}
