import { DocumentData, DocumentReference, Timestamp } from "firebase/firestore";
import {
  ConfirmedStatus,
  IssueLogTagSuggestion,
  RawStateChange,
} from "redux/users/types";
import { v4 } from "uuid";

export type CountsState = {
  loadingCounts: boolean;
  loadingUpdate: boolean;
  loadingBulkEdit: boolean;
  loadingIssue: boolean;
  loadingUpdateIssue: Record<string, boolean>;
  loadingCreateIssue: boolean;
  isUpdateIssueFailed: boolean | null;
  counts: Count[];
  issueLogs: IssueLog[];
};

export type ItemGroup = {
  /**
   * The type of the group.
   */
  type: string;
  /**
   * The total value of the group.
   */
  total: number;
  /**
   * An array of items in the group.
   */
  items: {
    /**
     * The name of the item.
     */
    name: string;
    /**
     * The size of the item.
     */
    size: number;
    /**
     * The unit of measurement for the item.
     */
    unit: string;
    /**
     * The quantity of the item.
     */
    quantity: number;
    /**
     * The price of the item.
     */
    price: number;
    /**
     * The total value of the item.
     */
    total: number;
  }[];
};

export type Count = {
  createdAt: Timestamp;
  deleted: boolean;
  startTime: number;
  endTime: number;
  sortKey: number;
  state: string;
  isDone: boolean;
  dismissed: boolean;
  id: string;
  areaReport?: string;
  stateChanges?: RawStateChange[];
  report?: string;
  stockDownloaded?: boolean;
  varianceDownloaded?: boolean;
  varianceReport?: string;
  ref: DocumentReference;
  initialLockDate?: Timestamp;
  confirmedStatus?: ConfirmedStatus;
  liveValueTotal?: number;
  liveValueItemGroups?: ItemGroup[];
};

export type CountReport = {
  id: string;
  name: string;
  size: number;
  unit: string;
  type: string;
  variety: string;
  cost: number;
  quantity: number;
  extra: number;
  deleted: boolean;
  areaId: string;
  areaName: string;
  sortReport: null;
}[];

export class IssueLog {
  id: string;
  title: string;
  status: "resolved" | "unresolved";
  description: string;
  tags: IssueLogTagSuggestion[];
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  countId: string;
  updatedBy?: string;
  deleted: boolean;

  constructor(id: string, data: DocumentData) {
    this.id = id;
    this.title = data.title ?? "";
    this.status = data.status ?? "";
    this.description = data.description ?? "";
    this.tags = data.tags?.length
      ? data.tags.map((tag: string | IssueLogTagSuggestion) => {
          if (typeof tag === "string") {
            return { key: v4(), tagLabel: tag, color: "" };
          } else return tag;
        })
      : [];
    this.createdAt = data.createdAt?.toDate();
    this.updatedAt = data.updatedAt?.toDate() || null;
    this.createdBy = data.createdBy ?? "";
    this.countId = data.countId ?? "";
    this.updatedBy = data.updatedBy ?? "";
    this.deleted = data.deleted ?? false;
  }
}

export type CountItem = {
  areaId: string;
  calc: string;
  cost: number;
  countId: string;
  createdAt: Timestamp;
  createdBy: string;
  deleted: boolean;
  deliveryCost: number;
  extra: number;
  id: string;
  isCorrectCount: boolean;
  isNotYetSynced: boolean;
  isPerKiloL: boolean;
  itemId: string;
  kickbackCost: number;
  quantity: number;
  tollCost: number;
  updated: number;
  updatedAt: Timestamp;
};
