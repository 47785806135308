import { createReducer } from "@reduxjs/toolkit";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import _ from "lodash";
import {
  Invoice,
  InvoiceState,
  InvoiceVote,
  Items,
  UserStatusInvoices,
} from "redux/invoice/types";
import actions from "./actions";

export const invoiceInitialState: InvoiceState = {
  invoices: [],
  loading: false,
  loadingInvoice: false,
  loadingItems: false,
  loadingItemCreate: false,
  loadingItemUpdate: false,
  newItemId: "",
  userItems: [],
  tableViewInvoices: {} as { data: Invoice[]; dateFrom: Date; dateTo: Date },
  userInvoicesTable: {},
  userItemsTable: {},
  userFeesTable: {},
  userId: "",
  ocrData: null,
  itemCreateWidth: -1,
  loadingOcrData: false,
  loadingDocumentAIData: false,
  isUploading: false,
  downloadURL: "",
  documentAIData: [],
  loadingVotes: false,
  loadingSave: false,
  votes: { data: [] } as {
    data: InvoiceVote[];
    firstDoc?: QueryDocumentSnapshot<DocumentData>;
    lastDoc?: QueryDocumentSnapshot<DocumentData>;
  },
  loadingInvoiceVotes: false,
  invoiceVotes: [],
  fees: [],
  isStrictSearchEnabled: true,
  loadingInvoiceVoteDraft: false,
  resolvingDraftInvoice: "",
  votesResults: {},
  userStatus: {} as UserStatusInvoices,
  isFloatingButtonsVisible: false,
  loadingUploadAndCreateInvoice: false,
  isForSupervisorFilter: false,
  unresolvedPriorityListCount: undefined,
  uncheckedPriorityListCount: undefined,
  isShortcutGuideButtonVisible: false,
  loadingInvoiceResolvingMetrics: false,
  invoiceResolvingMetrics: [],
  loadingInvoiceCheckingMetrics: false,
  invoiceCheckingMetrics: [],

  isInvoiceCheckingAdvancedFilterHasActiveFilter: false,
};

export default createReducer(invoiceInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addCase(actions.UPDATE_USER_INVOICE_TABLE, (state, action) => ({
    ...state,
    userInvoicesTable: {
      ...state.userInvoicesTable,
      [action.payload.userId]: {
        invoice: action.payload.invoice,
        loading: action.payload.loading,
      },
    },
  }));
  builder.addCase(actions.UPDATE_USER_ITEMS_TABLE, (state, action) => ({
    ...state,
    userItemsTable: {
      ...state.userItemsTable,
      [action.payload.userId]: {
        userItems: action.payload.userItems,
        loading: action.payload.loading,
      },
    },
  }));
  builder.addCase(actions.UPDATE_USER_FEES_TABLE, (state, action) => ({
    ...state,
    userFeesTable: {
      ...state.userFeesTable,
      [action.payload.userId]: {
        userFees: action.payload.userFees,
        loading: action.payload.loading,
      },
    },
  }));
  builder.addCase(actions.REMOVE_USER_ITEMS_TABLE, (state, action) => {
    const userItemsTable = { ...state.userItemsTable };
    for (const userId of action.payload.userIds) {
      delete userItemsTable[userId];
    }
    return { ...state, userItemsTable };
  });
  builder.addCase(actions.REMOVE_USER_FEES_TABLE, (state, action) => {
    const userFeesTable = { ...state.userFeesTable };
    for (const userId of action.payload.userIds) {
      delete userFeesTable[userId];
    }
    return { ...state, userFeesTable };
  });
  builder.addCase(actions.UPDATE_INVOICE_STATE, (state, action) => {
    const inv: Invoice[] = [...state.invoices];
    return {
      ...state,
      invoices: inv.map((obj) =>
        action.payload.id === obj.id ? (action.payload as Invoice) : obj
      ),
    };
  });
  builder.addCase(actions.UPDATE_DOCUMENT_AI_TABLE_DATA, (state, action) => {
    const { fileName, tables } = action.payload;
    const documentAIData = state.documentAIData.map((obj) => {
      if (obj.fileName === fileName) {
        obj.document.pages.tables = tables;
      }
      return obj;
    });
    return { ...state, ...documentAIData };
  });
  builder.addCase(actions.SET_USER_ITEM_ALIASES, (state, action) => {
    const { itemId, aliases } = action.payload;
    const itemsCopy = _.cloneDeep(state.userItems);
    const newUserItems = itemsCopy.map((obj) => {
      if (obj.id === itemId) {
        obj.aliases = _.uniq(aliases);
      }
      return obj;
    });

    return { ...state, userItems: newUserItems };
  });

  builder.addCase(actions.INITIALIZE_USER_ITEM_ALIASES, (state, action) => {
    const { newAliasesToInitialize } = action.payload;
    const itemsCopy: Items[] = JSON.parse(JSON.stringify(state.userItems));

    newAliasesToInitialize.forEach((aliasObject) => {
      Object.keys(aliasObject).forEach((itemId) => {
        const aliases = aliasObject[itemId];
        const existingItem = itemsCopy.find((obj) => obj.id === itemId);
        if (existingItem) {
          existingItem.aliases = existingItem.aliases
            ? _.uniq([...existingItem.aliases, aliases])
            : [aliases];
        }
      });
    });

    return { ...state, userItems: itemsCopy };
  });

  builder.addCase(actions.SET_USER_FEES_ALIASES, (state, action) => {
    const { itemId, aliases } = action.payload;
    const feesCopy = _.cloneDeep(state.fees);
    const newFees = feesCopy.map((obj) => {
      if (obj.id === itemId) {
        obj.aliases = _.uniq(aliases);
      }
      return obj;
    });

    return { ...state, fees: newFees };
  });

  builder.addCase(actions.SET_USER_STATUS_INVOICES, (state, action) => ({
    ...state,
    userStatus: {
      ...state.userStatus,
      ...action.payload,
    },
  }));
  builder.addDefaultCase((state) => state);
});
