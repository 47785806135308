import { DocumentData, Timestamp } from "firebase/firestore";
import { UserRecipe } from "redux/pos-item-tasks/types";
import { ReportData } from "redux/users/types";

export type ItemsState = {
  itemLoading: boolean;
  globalLoading: boolean;
  itemGlobalAssignmentLoading: boolean;
  loadingSaving: boolean;
  recipesLoading: boolean;
  items: UserItem[];

  globalItems: any[];
  queriedUserItemsCount: number;
  silhouettes: Silhouette[];
  recipes: UserRecipe[];
  searchMoreLoading: boolean;
  loadingZeroCostItemTasks: boolean;
  zeroCostItemTasks: ZeroCostItemTask[];
};

export type Silhouette = {
  name: string;
  url: string;
};

export type UserItem = {
  archived: boolean;
  avgCost: number | null;
  cost: number;
  kickbackCost: number;
  deliveryCost: number;
  tollCost: number;
  createdAt: Timestamp | null;
  deleted: boolean;
  globalId: string | null;

  globalData: any;
  hasSilhouette: boolean;
  id: string;
  isNew: boolean;
  itemId: string | null;
  name: string;
  size: number;
  stock: number;
  type: string;
  unit: string;
  updatedAt: Timestamp;
  userId: string;
  userName: string;
  variety: string;
  isCreatedByStockifi?: boolean;
  isUsedAtInvoices?: boolean;

  autoTotal: boolean;
  autoQuantity: boolean;
  isFee: boolean;
  used: boolean;
  nameChanged: boolean;
  aliases: string[];
  silhouettePath: string | null;
  bundleName?: string;
};

interface GlobalItemData {
  avgCost: number;
  bottleType?: string;
  id: string;
  name: string;
  silhouetteId?: string;
  size: number | string;
  taxGroup?: string;
  type: string;
  unit: string;
  variety: string;
}

export interface TypesenseFilter {
  attribute: string;
  value: string | number | string[];
  operator?: ">" | "<" | "!=" | "=";
  logical?: "AND" | "OR";
}

export interface TypesenseUserItem {
  alcohol?: string;
  archived: boolean;
  avgCost?: number;
  bottleType?: string;
  cost: number;
  deleted: boolean;
  globalData?: GlobalItemData;
  globalId?: string;
  id: string;
  itemId: string;
  name: string;
  silhouetteId?: string;
  silhouetteName?: string;
  size: number;
  stock?: number;
  taxGroup?: string;
  timestamp?: number;
  type: string;
  unit: string;
  used: boolean;
  userId: string;
  variety: string;
  typesenseId: string;
}

export type UpdateVarianceResponse = {
  data: ReportData;
  name: string;
  items: { data: { text: unknown }[]; id: string }[];
};

export type ItemCostHistory = {
  itemId: string;
  cost: number;
  sourcePath: string;
  updatedAt: Timestamp;
  source: string;
  createdAt: Timestamp;
};

export class ZeroCostItemTask {
  createdAt: Date;
  data: {
    itemId: string;
  };
  deleted: boolean;
  id: string;
  path: string;
  title: string;
  type: "zeroCostItem";
  updatedAt: Date;
  itemName: string;

  constructor(id: string, data: DocumentData) {
    this.createdAt = data.createdAt.toDate();
    this.updatedAt = data.updatedAt.toDate();
    this.deleted = data.deleted ?? false;
    this.id = id;
    this.path = data.path;
    this.title = data.title ?? "N/A";
    this.type = data.type ?? "zeroCostItem";
    this.data = {
      itemId: data.data.itemId,
    };
    this.itemName = "";
  }
}
