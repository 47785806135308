import { DocumentData, DocumentReference } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { APTransaction } from "redux/ap-transactions/types";
import store from "redux/store";
import { ServiceReturn } from "redux/types";
import {
  ConfirmedStatus,
  IssueLogTagSuggestion,
  User,
  UserActivity,
} from "redux/users/types";
import {
  setIssueLogTagSuggestions,
  setNoteSuggestions,
  setOnboardingUsersListener,
  setSelectedUserListener,
  setUserActivitiesListener,
  setUserTransactionsListener,
  setUsersListener,
} from "services/listeners/users";
import {
  addActivityNote,
  batchDismissUserCount,
  batchUpdateUsers,
  deleteActivityNote,
  dismissUserCount,
  editNoteSuggestions,
  editTableColumns,
  getTableColumns,
  getUserTransactionFile,
  getUsers,
  updateActivityNote,
  updateCountConfirmedStatus,
  updateIssueLogsTagSuggestions,
  updateNoteSuggestions,
  updateTransactionsResolved,
  updateUser,
  updateUserConfirmedStatus,
} from "services/users";
import { COMMON, USER } from "utils/constants";
import actions from "./actions";
import { getUserName } from "./helpers";

export interface GET_USERS_Payload {
  loadAllUsers: boolean;
  isNotFirstLoad?: boolean;
}
export function* GET_USERS(input: ReturnType<typeof actions.GET_USERS>) {
  const { isNotFirstLoad = false, loadAllUsers } = input.payload || {};
  if (!isNotFirstLoad) {
    yield put(
      actions.SET_STATE({
        loading: true,
      })
    );
  }

  const users: User[] = yield call(getUsers, loadAllUsers);
  yield put(
    actions.SET_STATE({
      users,
      loading: false,
    })
  );
}

export function* SUBSCRIBE_TO_ONBOARDING_USERS() {
  yield put(
    actions.SET_STATE({
      loading: true,
      users: [],
    })
  );

  const channel: EventChannel<boolean> = yield call(setOnboardingUsersListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USERS);
    channel.close();
  });

  try {
    while (true) {
      let users: User[] = yield take(channel);

      users = users.map((doc) => ({
        ...doc,
        organizationName: doc.organizationId
          ? users.find((u) => u.id === doc.organizationId)?.name
          : undefined,
      }));

      yield put(
        actions.SET_STATE({
          users,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(
      actions.SET_STATE({
        loading: false,
      })
    );
    if (c) {
      channel.close();
    }
  }
}

export interface SUBSCRIBE_TO_USERS_payload {
  loadAllUsers: boolean;
  isNotFirstLoad?: boolean;
}

export function* SUBSCRIBE_TO_USERS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USERS>
) {
  const { loadAllUsers, isNotFirstLoad } = input.payload;

  if (!isNotFirstLoad) {
    yield put(
      actions.SET_STATE({
        loading: true,
        users: [],
      })
    );
  }

  const channel: EventChannel<boolean> = yield call(
    setUsersListener,
    loadAllUsers
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USERS);
    channel.close();
  });

  try {
    while (true) {
      const users: User[] = yield take(channel);
      if (users.length > 1) {
        yield put(
          actions.SET_STATE({
            users,
            loading: false,
          })
        );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(
      actions.SET_STATE({
        loading: false,
      })
    );
    if (c) {
      channel.close();
    }
  }
}

export interface SUBSCRIBE_TO_SELECTED_USERID_payload {
  userId: string;
}

export function* SUBSCRIBE_TO_SELECTED_USERID(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_SELECTED_USERID>
) {
  const { userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSelectedUser: true,
      selectedUser: null,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setSelectedUserListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_SELECTED_USERID);
    yield put(actions.SET_STATE({ selectedUser: null }));
    channel.close();
  });

  try {
    while (true) {
      const selectedUser: User = yield take(channel);
      yield put(
        actions.SET_STATE({
          selectedUser,
          loadingSelectedUser: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(
      actions.SET_STATE({
        loadingSelectedUser: false,
      })
    );
    if (c) {
      channel.close();
    }
  }
}

export interface DISMISS_USER_COUNT_Payload {
  userId: string;
  countId: string;
}
function* DISMISS_USER_COUNT({
  payload,
}: ReturnType<typeof actions.DISMISS_USER_COUNT>) {
  const result: ServiceReturn = yield call(
    dismissUserCount,
    payload.userId,
    payload.countId
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_DISMISSED,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface DISMISS_ALL_USER_COUNT_Payload {
  users: User[];
  state: string;
}
function* DISMISS_ALL_USER_COUNT({
  payload,
}: ReturnType<typeof actions.DISMISS_ALL_USER_COUNT>) {
  const result: ServiceReturn = yield call(
    batchDismissUserCount,
    payload.users,
    payload.state
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_DISMISSED,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface UPDATE_USER_CONFIRMED_STATUS_Payload {
  userId: string;
  confirmedStatus: ConfirmedStatus;
  updatedField: string;
}
function* UPDATE_USER_CONFIRMED_STATUS({
  payload,
}: ReturnType<typeof actions.UPDATE_USER_CONFIRMED_STATUS>) {
  const auth = store.getState().auth;
  const authUserId = auth.user ?? "";
  const authName = getUserName(authUserId);
  const username =
    authUserId !== authName
      ? authName
      : (auth.authClaims?.email as string | null) ?? authUserId;

  const result: ServiceReturn = yield call(
    updateUserConfirmedStatus,
    payload.userId,
    payload.confirmedStatus,
    authUserId,
    username,
    payload.updatedField
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_CONFIRMED_STATUS,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface UPDATE_COUNT_CONFIRMED_STATUS_Payload {
  userId: string;
  countId: string;
  confirmedStatus: ConfirmedStatus;
  updatedField: string;
  lockCount?: boolean;
}
function* UPDATE_COUNT_CONFIRMED_STATUS({
  payload,
}: ReturnType<typeof actions.UPDATE_COUNT_CONFIRMED_STATUS>) {
  const auth = store.getState().auth;
  const authUserId = auth.user ?? "";
  const authName = getUserName(authUserId);
  const username =
    authUserId !== authName
      ? authName
      : (auth.authClaims?.email as string | null) ?? authUserId;

  const result: ServiceReturn = yield call(
    updateCountConfirmedStatus,
    payload.userId,
    payload.countId,
    payload.confirmedStatus,
    authUserId,
    username,
    payload.updatedField,
    payload.lockCount
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_CONFIRMED_STATUS,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface SUBSCRIBE_USER_TRANSACTIONS_Payload {
  userId: string;
  targetDate?: Date;
}

function* SUBSCRIBE_USER_TRANSACTIONS(
  input: ReturnType<typeof actions.SUBSCRIBE_USER_TRANSACTIONS>
) {
  const { userId, targetDate } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingUserTransactions: true,
      userTransactions: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setUserTransactionsListener,
    userId,
    targetDate
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_USER_TRANSACTIONS);
    channel.close();
  });

  try {
    while (true) {
      const userTransactions: APTransaction[] = yield take(channel);

      yield put(
        actions.SET_STATE({
          loadingUserTransactions: false,
          userTransactions: userTransactions,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}
export interface UPDATE_USER_Payload {
  user: Partial<User>;
  userId: string;
}

export function* UPDATE_USER(input: ReturnType<typeof actions.UPDATE_USER>) {
  const { user, userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(updateUser, user, userId);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(
      alertActions.SUCCESS({
        message: "User data updated.",
        data: {
          payload: input.payload,
        },
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: input.payload,
          error: result.error,
        },
      })
    );
  }
}

export interface BATCH_UPDATE_USER_Payload {
  usersArray: string[];
  data: DocumentData;
}

function* BATCH_UPDATE_USER(
  input: ReturnType<typeof actions.BATCH_UPDATE_USER>
) {
  const { usersArray, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingBulkEdit: true,
    })
  );

  const result: ServiceReturn = yield call(batchUpdateUsers, data, usersArray);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
    yield put(
      alertActions.SUCCESS({
        message: "User data updated.",
        data: {
          payload: input.payload,
        },
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: input.payload,
          error: result.error,
        },
      })
    );
  }
}

export interface UPDATE_USERS_TRANSACTIONS_RESOLVED_Payload {
  transactionRefs: { ref: DocumentReference }[];
  resolved: boolean;
  resolvedBy: string;
}

function* UPDATE_USERS_TRANSACTIONS_RESOLVED({
  payload,
}: ReturnType<typeof actions.UPDATE_USERS_TRANSACTIONS_RESOLVED>) {
  const { transactionRefs, resolved, resolvedBy } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  // call service for update
  const result: ServiceReturn = yield call(
    updateTransactionsResolved,
    transactionRefs,
    resolved,
    resolvedBy
  );

  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: resolved
          ? USER.TRANSACTION_RESOLVED
          : USER.TRANSACTION_UNRESOLVED,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdate: false,
    })
  );
}

export interface GET_USER_TRANSACTION_DOWNLOAD_URL_Payload {
  paths: string[];
}

export function* GET_USER_TRANSACTION_DOWNLOAD_URL(
  input: ReturnType<typeof actions.GET_USER_TRANSACTION_DOWNLOAD_URL>
) {
  const { paths } = input.payload;
  for (let i = 0; i < paths.length; i++) {
    const result: ServiceReturn = yield call(getUserTransactionFile, paths[i]);

    if (result.data) {
      const fileName = paths[i].substring(paths[i].lastIndexOf("/") + 1);

      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = result.data;
      anchor.target = "_blank";
      anchor.click();
      anchor.remove();
    } else {
      yield put(
        alertActions.ERROR({
          message: result.error || COMMON.REQUEST_ERROR,
          data: {
            error: result.error,
            payload: input.payload,
            data: result.data,
          },
        })
      );
    }
  }
}

export interface GET_USER_TABLE_COLUMNS_Payload {
  module: string;
}

function* GET_USER_TABLE_COLUMNS(
  input: ReturnType<typeof actions.GET_USER_TABLE_COLUMNS>
) {
  const { module } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingTableColumns: true,
    })
  );

  const result: ServiceReturn = yield call(getTableColumns, module);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        tableColumns: result.data,
        loadingTableColumns: false,
      })
    );
  }
}

export interface UPDATE_USER_TABLE_COLUMNS_Payload {
  data: Record<string, number>;
  module: string;
}

function* UPDATE_USER_TABLE_COLUMNS(
  input: ReturnType<typeof actions.UPDATE_USER_TABLE_COLUMNS>
) {
  const { data, module } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSaveColumnOrder: true,
    })
  );

  const result: ServiceReturn = yield call(editTableColumns, data, module);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingSaveColumnOrder: false }));
    yield put(
      alertActions.SUCCESS({
        message: "Table columns updated.",
        data: {
          payload: input.payload,
        },
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingSaveColumnOrder: false }));
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: input.payload,
          error: result.error,
        },
      })
    );
  }
}

export interface GET_USER_POS_ITEMS_BY_TYPE_Payload {
  userId: string;
  type: string;
}

function* SUBSCRIBE_REPORT_GUIDELINES_NOTE_SUGGESTIONS() {
  yield put(
    actions.SET_STATE({
      loadingNoteSuggestions: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setNoteSuggestions,
    "reportGuidelinesSuggestions"
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_REPORT_GUIDELINES_NOTE_SUGGESTIONS);
    yield put(
      actions.SET_STATE({
        reportGuidelinesSuggestions: [],
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const noteSuggestions: string[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          reportGuidelinesSuggestions: noteSuggestions,
          loadingNoteSuggestions: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingNoteSuggestions: false,
        })
      );
    }
  }
}
function* SUBSCRIBE_INVOICE_NOTE_SUGGESTIONS() {
  yield put(
    actions.SET_STATE({
      loadingNoteSuggestions: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setNoteSuggestions,
    "invoiceNoteSuggestions"
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_INVOICE_NOTE_SUGGESTIONS);
    yield put(
      actions.SET_STATE({
        invoiceNoteSuggestions: [],
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const noteSuggestions: string[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          invoiceNoteSuggestions: noteSuggestions,
          loadingNoteSuggestions: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingNoteSuggestions: false,
        })
      );
    }
  }
}
function* SUBSCRIBE_TASK_NOTE_SUGGESTIONS() {
  yield put(
    actions.SET_STATE({
      loadingNoteSuggestions: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setNoteSuggestions,
    "taskNoteSuggestions"
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_TASK_NOTE_SUGGESTIONS);
    yield put(
      actions.SET_STATE({
        taskNoteSuggestions: [],
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const noteSuggestions: string[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          taskNoteSuggestions: noteSuggestions,
          loadingNoteSuggestions: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingNoteSuggestions: false,
        })
      );
    }
  }
}

function* SUBSCRIBE_ISSUE_LOGS_TAG_SUGGESTIONS() {
  yield put(
    actions.SET_STATE({
      loadingIssueLogTagSuggestions: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(setIssueLogTagSuggestions);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_ISSUE_LOGS_TAG_SUGGESTIONS);
    yield put(
      actions.SET_STATE({
        issueLogTagSuggestions: [],
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const issueLogTagSuggestions: IssueLogTagSuggestion[] =
        yield take(channel);
      yield put(
        actions.SET_STATE({
          issueLogTagSuggestions: issueLogTagSuggestions,
          loadingIssueLogTagSuggestions: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingIssueLogTagSuggestions: false,
        })
      );
    }
  }
}

export interface UPDATE_ISSUE_LOGS_TAG_SUGGESTIONS_Payload {
  newTagSuggestions: IssueLogTagSuggestion[];
}

function* UPDATE_ISSUE_LOGS_TAG_SUGGESTIONS({
  payload,
}: ReturnType<typeof actions.UPDATE_ISSUE_LOGS_TAG_SUGGESTIONS>) {
  const { newTagSuggestions } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdateissueLogTagSuggestions: true,
    })
  );

  // call service for update
  const result: ServiceReturn = yield call(
    updateIssueLogsTagSuggestions,
    newTagSuggestions
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Issue logs tag suggestions updated."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdateissueLogTagSuggestions: false,
    })
  );
}

export interface UPDATE_NOTE_SUGGESTIONS_Payload {
  newNoteSuggestion: string;
  docName: string;
}

function* UPDATE_NOTE_SUGGESTIONS({
  payload,
}: ReturnType<typeof actions.UPDATE_NOTE_SUGGESTIONS>) {
  const { newNoteSuggestion, docName } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdateNoteSuggestions: true,
    })
  );

  // call service for update
  const result: ServiceReturn = yield call(
    updateNoteSuggestions,
    newNoteSuggestion,
    docName
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Suggestions notes updated."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdateNoteSuggestions: false,
    })
  );
}

export interface EDIT_NOTE_SUGGESTIONS_Payload {
  newNoteSuggestionsArr: string[];
  docName: string;
}

function* EDIT_NOTE_SUGGESTIONS({
  payload,
}: ReturnType<typeof actions.EDIT_NOTE_SUGGESTIONS>) {
  const { newNoteSuggestionsArr, docName } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdateNoteSuggestions: true,
    })
  );

  // call service for update
  const result: ServiceReturn = yield call(
    editNoteSuggestions,
    newNoteSuggestionsArr,
    docName
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Suggestions notes updated."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdateNoteSuggestions: false,
    })
  );
}

export interface ADD_ACTIVITY_NOTE_Payload {
  userId: string;
  content: string;
  countId: string;
  authUserId: string;
}

function* ADD_ACTIVITY_NOTE({
  payload,
}: ReturnType<typeof actions.ADD_ACTIVITY_NOTE>) {
  const { userId, content, countId, authUserId } = payload;

  yield put(
    actions.SET_STATE({
      loadingAddActivityNote: true,
    })
  );

  const auth = store.getState().auth;
  const authName = getUserName(authUserId);
  const username =
    authUserId !== authName
      ? authName
      : (auth.authClaims?.email as string | null) ?? authUserId;

  const result: ServiceReturn = yield call(
    addActivityNote,
    userId,
    content,
    countId,
    authUserId,
    username
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Note successfully added."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingAddActivityNote: false,
    })
  );
}

export interface UPDATE_ACTIVITY_NOTE_Payload {
  userId: string;
  activityId: string;
  content: string;
  authUserId: string;
}

function* UPDATE_ACTIVITY_NOTE({
  payload,
}: ReturnType<typeof actions.UPDATE_ACTIVITY_NOTE>) {
  const { userId, activityId, content, authUserId } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdateActivityNote: true,
    })
  );

  const result: ServiceReturn = yield call(
    updateActivityNote,
    userId,
    activityId,
    content,
    authUserId
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Note successfully updated."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdateActivityNote: false,
    })
  );
}

export interface DELETE_ACTIVITY_NOTE_Payload {
  userId: string;
  activityId: string;
  authUserId: string;
}

function* DELETE_ACTIVITY_NOTE({
  payload,
}: ReturnType<typeof actions.DELETE_ACTIVITY_NOTE>) {
  const { userId, activityId, authUserId } = payload;

  yield put(
    actions.SET_STATE({
      loadingDeleteActivityNote: true,
    })
  );

  const result: ServiceReturn = yield call(
    deleteActivityNote,
    userId,
    activityId,
    authUserId
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Note successfully deleted."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingDeleteActivityNote: false,
    })
  );
}

export interface SUBSCRIBE_TO_ACTIVITIES_payload {
  userId: string;
  countId: string;
}

export function* SUBSCRIBE_TO_ACTIVITIES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_ACTIVITIES>
) {
  const { userId, countId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSelectedUserActivities: true,
      selectedUserActivities: [],
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setUserActivitiesListener,
    userId,
    countId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_TO_ACTIVITIES);
    channel.close();
  });

  try {
    while (true) {
      const activities: UserActivity[] = yield take(channel);

      yield put(
        actions.SET_STATE({
          selectedUserActivities: activities,
          loadingSelectedUserActivities: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(
      actions.SET_STATE({
        loadingSelectedUserActivities: false,
      })
    );
    if (c) {
      channel.close();
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USERS, GET_USERS),
    takeLatest(actions.UPDATE_USER, UPDATE_USER),
    takeLatest(actions.BATCH_UPDATE_USER, BATCH_UPDATE_USER),
    takeLatest(actions.DISMISS_ALL_USER_COUNT, DISMISS_ALL_USER_COUNT),
    takeLatest(actions.SUBSCRIBE_TO_USERS, SUBSCRIBE_TO_USERS),
    takeLatest(actions.DISMISS_USER_COUNT, DISMISS_USER_COUNT),
    takeLatest(
      actions.UPDATE_USER_CONFIRMED_STATUS,
      UPDATE_USER_CONFIRMED_STATUS
    ),
    takeLatest(
      actions.UPDATE_COUNT_CONFIRMED_STATUS,
      UPDATE_COUNT_CONFIRMED_STATUS
    ),
    takeLatest(
      actions.SUBSCRIBE_USER_TRANSACTIONS,
      SUBSCRIBE_USER_TRANSACTIONS
    ),
    takeLatest(
      actions.UPDATE_USERS_TRANSACTIONS_RESOLVED,
      UPDATE_USERS_TRANSACTIONS_RESOLVED
    ),
    takeLatest(
      actions.GET_USER_TRANSACTION_DOWNLOAD_URL,
      GET_USER_TRANSACTION_DOWNLOAD_URL
    ),
    takeLatest(actions.UPDATE_USER_TABLE_COLUMNS, UPDATE_USER_TABLE_COLUMNS),
    takeLatest(actions.GET_USER_TABLE_COLUMNS, GET_USER_TABLE_COLUMNS),
    takeLatest(
      actions.SUBSCRIBE_REPORT_GUIDELINES_NOTE_SUGGESTIONS,
      SUBSCRIBE_REPORT_GUIDELINES_NOTE_SUGGESTIONS
    ),
    takeLatest(
      actions.SUBSCRIBE_INVOICE_NOTE_SUGGESTIONS,
      SUBSCRIBE_INVOICE_NOTE_SUGGESTIONS
    ),
    takeLatest(
      actions.SUBSCRIBE_TASK_NOTE_SUGGESTIONS,
      SUBSCRIBE_TASK_NOTE_SUGGESTIONS
    ),
    takeLatest(actions.UPDATE_NOTE_SUGGESTIONS, UPDATE_NOTE_SUGGESTIONS),
    takeLatest(
      actions.SUBSCRIBE_TO_SELECTED_USERID,
      SUBSCRIBE_TO_SELECTED_USERID
    ),
    takeLatest(
      actions.SUBSCRIBE_TO_ONBOARDING_USERS,
      SUBSCRIBE_TO_ONBOARDING_USERS
    ),
    takeLatest(
      actions.SUBSCRIBE_ISSUE_LOGS_TAG_SUGGESTIONS,
      SUBSCRIBE_ISSUE_LOGS_TAG_SUGGESTIONS
    ),
    takeLatest(actions.ADD_ACTIVITY_NOTE, ADD_ACTIVITY_NOTE),
    takeLatest(actions.UPDATE_ACTIVITY_NOTE, UPDATE_ACTIVITY_NOTE),
    takeLatest(actions.DELETE_ACTIVITY_NOTE, DELETE_ACTIVITY_NOTE),
    takeLatest(actions.SUBSCRIBE_TO_ACTIVITIES, SUBSCRIBE_TO_ACTIVITIES),
    takeLatest(actions.EDIT_NOTE_SUGGESTIONS, EDIT_NOTE_SUGGESTIONS),
    takeLatest(
      actions.UPDATE_ISSUE_LOGS_TAG_SUGGESTIONS,
      UPDATE_ISSUE_LOGS_TAG_SUGGESTIONS
    ),
  ]);
}
