import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { Items } from "redux/invoice/types";
import { ZeroCostItemTask } from "redux/items/types";
import { db } from "services/firebase";

function globalDataUserItems(doc: QueryDocumentSnapshot[]): Items[] {
  return doc.map((x) => {
    const id = x.id;
    const data = x.data();
    const aliasesToUsed = data.aliases?.filter((x: string) => x) ?? [];
    data.cost = Number(data.cost);

    if (data.globalData) {
      const { name, ...globalData } = data.globalData;
      return { ...data, ...globalData, id: id, aliases: aliasesToUsed };
    }
    return {
      ...data,
      id: id,
      aliases: aliasesToUsed,
    };
  });
}

export function setUserItemsListener(userId: string): EventChannel<Items[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      collection(db, "users", userId, "items"),
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data = globalDataUserItems(snapshot.docs);
        const userItems: Items[] = [...data];
        emitter(userItems);
      }
    );

    return () => unsub();
  });
}

export function setZeroCostItemTasksListener(
  userId: string
): EventChannel<ZeroCostItemTask[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const constraints = [
      where("type", "==", "zeroCostItem"),
      where("deleted", "==", false),
    ];

    const unsub = onSnapshot(
      query(collection(db, "users", userId, "tasks"), ...constraints),
      (snapshot) => {
        const data: ZeroCostItemTask[] = snapshot.docs.map(
          (x) => new ZeroCostItemTask(x.id, x.data())
        );
        const tasks: ZeroCostItemTask[] = data.map((x) => ({
          ...x,
          itemName: x.title.replace(" has a cost of 0", ""),
        }));

        emitter(tasks);
      }
    );

    return () => unsub();
  });
}
