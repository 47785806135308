import { DocumentData, DocumentReference } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import actions from "redux/counts/actions";
import { ServiceReturn } from "redux/types";
import { IssueLogTagSuggestion } from "redux/users/types";
import {
  batchUpdateCounts,
  createIssue,
  updateCount,
  updateIssueLog,
} from "services/counts";
import {
  setCountsListener,
  setIssueLogsListener,
} from "services/listeners/counts";
import { COMMON } from "utils/constants";
import { Count, IssueLog } from "./types";

export interface GET_COUNTS_Payload {
  userId: string;
  includeDeleted?: boolean;
}

function* SUBSCRIBE_TO_COUNTS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_COUNTS>
) {
  yield put(
    actions.SET_STATE({
      loadingCounts: true,
      counts: [],
    })
  );
  const { userId, includeDeleted } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setCountsListener,
    userId,
    includeDeleted
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_COUNTS);
    yield put(actions.SET_STATE({ counts: [] }));
    channel.close();
  });

  try {
    while (true) {
      const counts: Count[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          loadingCounts: false,
          counts,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingCounts: false,
        })
      );
    }
  }
}

export interface UPDATE_COUNT_Payload {
  countRef: DocumentReference;
  data: DocumentData;
}

export function* UPDATE_COUNT(input: ReturnType<typeof actions.UPDATE_COUNT>) {
  const { countRef, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(updateCount, countRef, data);
  if (result.data) {
    yield put(alertActions.SUCCESS("Count Updated"));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  }
}

export interface BATCH_UPDATE_COUNTS_Payload {
  countsArray: string[];
  userId: string;
  data: DocumentData;
}

function* BATCH_UPDATE_COUNTS(
  input: ReturnType<typeof actions.BATCH_UPDATE_COUNTS>
) {
  const { countsArray, userId, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingBulkEdit: true,
    })
  );

  const result: ServiceReturn = yield call(
    batchUpdateCounts,
    countsArray,
    userId,
    data
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS(
        `${countsArray.length} ${
          countsArray.length > 1 ? "counts have" : "count has"
        } been updated.`
      )
    );
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
  }
}

export interface CREATE_ISSUE_Payload {
  countId: string;
  title: string;
  description: string;
  tags: IssueLogTagSuggestion[];
  userId: string;
}

function* CREATE_ISSUE(input: ReturnType<typeof actions.CREATE_ISSUE>) {
  yield put(
    actions.SET_STATE({ loadingCreateIssue: true, isUpdateIssueFailed: null })
  );
  const { countId, title, description, userId, tags } = input.payload;

  const createdBy: string | undefined = yield select(
    (state) => state.auth.user
  );

  let isUpdateIssueFailed = true;
  if (!createdBy) {
    yield put(
      alertActions.ERROR("Current user id not found, please refresh your page.")
    );
  } else {
    const result: ServiceReturn = yield call(
      createIssue,
      title,
      description,
      tags,
      createdBy,
      userId,
      countId
    );

    if (result.data) {
      yield put(alertActions.SUCCESS("New issue created successfully."));
      isUpdateIssueFailed = false;
    } else {
      yield put(
        alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR)
      );
    }
  }

  yield put(
    actions.SET_STATE({ loadingCreateIssue: false, isUpdateIssueFailed })
  );
}

export interface UPDATE_ISSUE_LOG_Payload {
  userId: string;
  logId: string;
  newState: Partial<IssueLog>;
  title: string;
  countId: string;
}

function* UPDATE_ISSUE_LOG(input: ReturnType<typeof actions.UPDATE_ISSUE_LOG>) {
  yield put(
    actions.SET_STATE({
      loadingUpdateIssue: {
        [input.payload.logId]: true,
      },
    })
  );
  const { userId, logId, newState, title, countId } = input.payload;
  const updatedBy: string | undefined = yield select(
    (state) => state.auth.user
  );

  if (!updatedBy) {
    yield put(
      alertActions.ERROR("Current user id not found, please refresh your page.")
    );
  } else {
    const result: ServiceReturn = yield call(
      updateIssueLog,
      userId,
      logId,
      updatedBy,
      newState
    );

    if (result.data) {
      yield put(alertActions.SUCCESS("Issue state updated successfully."));
    } else {
      yield put(
        alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR)
      );
    }
  }

  yield put(
    actions.SET_STATE({
      loadingUpdateIssue: {
        [input.payload.logId]: false,
      },
    })
  );
}

export interface SUBSCRIBE_TO_ISSUE_LOGS_Payload {
  countId: string;
  userId: string;
}

function* SUBSCRIBE_TO_ISSUE_LOGS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_ISSUE_LOGS>
) {
  yield put(
    actions.SET_STATE({
      loadingIssue: true,
      issueLogs: [],
    })
  );
  const { userId, countId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setIssueLogsListener,
    userId,
    countId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_ISSUE_LOGS);
    yield put(actions.SET_STATE({ issueLogs: [] }));
    channel.close();
  });

  try {
    while (true) {
      const issueLogs: IssueLog[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          loadingIssue: false,
          issueLogs,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingIssue: false,
        })
      );
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBSCRIBE_TO_COUNTS, SUBSCRIBE_TO_COUNTS),
    takeLatest(actions.UPDATE_COUNT, UPDATE_COUNT),
    takeLatest(actions.BATCH_UPDATE_COUNTS, BATCH_UPDATE_COUNTS),
    takeLatest(actions.CREATE_ISSUE, CREATE_ISSUE),
    takeLatest(actions.UPDATE_ISSUE_LOG, UPDATE_ISSUE_LOG),
    takeLatest(actions.SUBSCRIBE_TO_ISSUE_LOGS, SUBSCRIBE_TO_ISSUE_LOGS),
  ]);
}
