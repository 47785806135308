import {
  Button,
  Classes,
  H5,
  Intent,
  Popover,
  PopoverPosition,
} from "@blueprintjs/core";
import React, { JSX } from "react";
import "./index.css";
import { cn } from "@stockifi/shared";

type Props = {
  text: string;
  title: string;
  onConfirm: React.EventHandler<React.SyntheticEvent>;
  onCancel?: React.EventHandler<React.SyntheticEvent>;
  children: JSX.Element;
  actionName?: string;
  disabled?: boolean;
  position?: PopoverPosition;
  style?: React.CSSProperties | undefined;
};

function ActionConfirmationDialogue({
  onConfirm,
  onCancel,
  text,
  title,
  children,
  actionName,
  disabled,
  position,
  style,
}: Props) {
  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      document.getElementById("confirm-button")?.click();
    }
  }
  function getContents(): JSX.Element {
    return (
      <div>
        <H5>{title}</H5>
        <p>{text}</p>
        <div className="button-display">
          <Button
            className={cn(Classes.POPOVER_DISMISS, "button-margin-right")}
            text="Cancel"
            onClick={onCancel}
          />
          <Button
            id="confirm-button"
            intent={Intent.DANGER}
            className={Classes.POPOVER_DISMISS}
            onClick={onConfirm}
            onKeyDown={onConfirm}
            text={actionName ?? "Delete"}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      onKeyDown={handleKeyDown}
      style={{ display: "inline-block", ...style }}
    >
      <Popover
        enforceFocus={true}
        content={getContents()}
        disabled={disabled}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        position={position ?? `auto`}
      >
        {children}
      </Popover>
    </div>
  );
}

export default ActionConfirmationDialogue;
