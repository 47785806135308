import { createActions } from "redux/create-actions";
import {
  CHANGE_DEFAULT_LAYOUT_Payload,
  CREATE_FORMAT_SET_Payload,
  CREATE_LAYOUT_Payload,
  CREATE_PERMISSION_Payload,
  DELETE_FORMAT_SET_Payload,
  DELETE_LAYOUT_Payload,
  REMOVE_DEFAULT_LAYOUT_Payload,
  UPDATE_FORMAT_SET_Payload,
  UPDATE_LAYOUT_ORDER_Payload,
  UPDATE_PERMISSIONS_Payload,
} from "./sagas";
import { ConfigState } from "./types";

const actions = createActions("config", {
  SET_STATE: {} as Partial<ConfigState>,
  // Advanced Filter Panel Layouts
  GET_LAYOUTS: undefined,
  CREATE_LAYOUT: {} as CREATE_LAYOUT_Payload,
  UPDATE_LAYOUT_ORDER: {} as UPDATE_LAYOUT_ORDER_Payload,
  CHANGE_DEFAULT_LAYOUT: {} as CHANGE_DEFAULT_LAYOUT_Payload,
  REMOVE_DEFAULT_LAYOUT: {} as REMOVE_DEFAULT_LAYOUT_Payload,
  DELETE_LAYOUT: {} as DELETE_LAYOUT_Payload,
  // Permissions Module
  GET_PERMISSIONS: undefined,
  CREATE_PERMISSION: {} as CREATE_PERMISSION_Payload,
  UPDATE_PERMISSIONS: {} as UPDATE_PERMISSIONS_Payload,
  // Conditional Formatting
  GET_FORMAT_SETS: undefined,
  CREATE_FORMAT_SET: {} as CREATE_FORMAT_SET_Payload,
  UPDATE_FORMAT_SET: {} as UPDATE_FORMAT_SET_Payload,
  DELETE_FORMAT_SET: {} as DELETE_FORMAT_SET_Payload,
});

export default actions;
