import {
  DocumentData,
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import moment from "moment";
import { Items } from "redux/invoice/types";
import {
  ItemCostHistory,
  Silhouette,
  TypesenseFilter,
  TypesenseUserItem,
  UpdateVarianceResponse,
} from "redux/items/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db, functions, storage } from "services/firebase";
import { SearchResponse } from "typesense/lib/Typesense/Documents";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { ITEM_TYPES, SERVER_COUNTS } from "utils/constants";

const processData = (respData: DocumentData[] | null, itemType: string) => {
  if (respData) {
    const dataArray = itemType === "global" ? respData.slice(1) : respData;
    const data: DocumentData[] = [];
    for (let i = 0; i < dataArray.length; i++) {
      data.push(JSON.parse(dataArray[i].data));
    }
    const merged = ([] as DocumentData[]).concat(...data);
    return postProcessData(merged, itemType);
  } else return [];
};

const postProcessData = (data: DocumentData[], itemType: string) => {
  return data.map((item) => ({
    ...item,
    hasSilhouette: Boolean(
      item.silhouetteData || item.silhouetteId || item.silhouetteName
    ),
    itemType,
    isCreatedByStockifi: item.isNew ? true : false,
  }));
};

export async function addUserItems(item: Items, userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const itemRef = collection(db, "users", userId, "items");
  return await addDoc(itemRef, { ...item, isCreatedByStockifi: true })
    .then((docRef) => {
      return { data: docRef.id, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateUserItems(item: Partial<Items>, userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const itemRef = doc(
    db,
    "users/" + userId + `/${item.isFee ? "fees" : "items"}/` + item.id
  );

  return updateDoc(itemRef, {
    name: item.name,
    aliases: item.aliases,
    nameChanged: item.nameChanged,
  })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateGlobalItem(itemId: string, newState: DocumentData) {
  if (!db) return { data: null, error: "No db connection" };
  const collectionRef = doc(db, "globalItems", itemId);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return updateDoc(collectionRef, { ...newState })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function queryItemsWithMultiSearch(
  query: string,
  perPage = 10,
  page = 1,
  filters?: unknown[]
) {
  try {
    if (!functions) return console.log("functions not initialized");

    const typesenseSearch = httpsCallable(
      functions,
      CALLABLE_FUNCTIONS.typesenseSearch
    );

    const response = await typesenseSearch({
      q: query,
      collection: "items",
      queryBy: "name,itemId",
      page: page,
      perPage,
      filters,
      isMultiSearch: true,
    });

    type MultiSearchResponse = {
      results: SearchResponse<TypesenseUserItem>[];
    };

    const resp = response.data as MultiSearchResponse;
    const results: unknown[] = [];
    let totalCount = 0;

    resp.results.forEach((r) => {
      totalCount += r.found;
      const datum = r.hits?.map((hit) => ({
        ...hit.document,
        id: hit.document.itemId,
        typesenseId: hit.document.id,
      }));
      if (datum) results.push(...datum);
    });

    return {
      data: { data: results, queriedUserItemsCount: totalCount },
      error: null,
    };
  } catch (error) {
    console.log(error);
    return { data: null, error };
  }
}

export async function updateUserItem(
  userId: string,
  itemId: string,
  newState: DocumentData
) {
  if (!db) return { data: null, error: "No db connection" };
  const collectionRef = doc(db, "users", userId, "items", itemId);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return updateDoc(collectionRef, { ...newState })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function batchUpdateUserItems(
  itemsArray: Partial<Items>[],
  userId: string
): Promise<ServiceReturn> {
  if (!db || !functions) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    while (itemsArray.length > 0) {
      const batch = writeBatch(db);
      for (const item of itemsArray.splice(0, 500)) {
        const { itemId, bundleName } = item;
        if (itemId) {
          const ref = doc(db, "users", userId, "items", itemId);
          batch.update(ref, {
            bundleName: bundleName ? bundleName : deleteField(),
          });
        }
      }
      await batch.commit();
    }
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function uploadSilhouette(file: File | null): Promise<Silhouette> {
  return await new Promise(function (resolve, reject) {
    if (!storage) return reject({ data: null, error: "No storage connection" });
    const path = "silhouettes/" + new Date().getTime() + "-" + file?.name;
    const storageRef = ref(storage, path);
    if (file) {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          return;
        },
        function error(err) {
          reject({ data: null, error: err });
        },
        function complete() {
          Promise.all([
            getDownloadURL(uploadTask.snapshot.ref),
            getMetadata(uploadTask.snapshot.ref),
          ]).then((data) => {
            resolve({
              url: data[0],
              name: data[1].name,
            });
          });
        }
      );
    }
  });
}

export async function getItemsAttribute() {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const collectionRef = doc(db, "docs", "items");
    const docSnap = await getDoc(collectionRef);
    let data: DocumentData = {};
    if (docSnap.exists()) {
      data = docSnap.data();
    }
    return { data: data?.keys ?? [], error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getLatestVarianceReport(
  userId: string,
  selectedCountId: string
) {
  if (!functions) return { data: null, error: "No functions connection" };

  try {
    const updateVarianceRes = httpsCallable<
      { userId: string; countId: string },
      UpdateVarianceResponse
    >(functions, CALLABLE_FUNCTIONS.updateVarianceReport, { timeout: 540000 });

    const updatedVarianceReport = await updateVarianceRes({
      countId: selectedCountId,
      userId: userId,
    });
    return { data: updatedVarianceReport, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

interface ISearchItems {
  query: string;
  isStrict: boolean;
  locale: string;
  page?: number;
  perPage?: number;
  includeQueryName?: boolean;
}

export async function searchItems({
  query,
  isStrict,
  locale,
  page = 1,
  perPage = 30,
  includeQueryName = false,
}: ISearchItems) {
  if (!functions) return { data: null, error: "No functions connection" };

  try {
    const localeType = Object.keys(
      ITEM_TYPES[locale === "en" ? "eng" : locale] ?? ITEM_TYPES["no"]
    ).map((x) => x);

    const typesenseSearch = httpsCallable(
      functions,
      CALLABLE_FUNCTIONS.typesenseSearch
    );
    const filters: TypesenseFilter[] = [
      {
        attribute: "deleted",
        operator: "!=",
        value: "true",
        logical: "AND",
      },
      {
        attribute: "archived",
        operator: "!=",
        value: "true",
        logical: "AND",
      },
      {
        attribute: "type",
        operator: "=",
        value: localeType,
        ...(isStrict && { logical: "AND" }),
      },
    ];

    if (isStrict)
      filters.push({
        attribute: "aliases",
        operator: "=",
        value: `[${query}]`,
      });

    const response = await typesenseSearch({
      q: isStrict ? `*` : query,
      collection: "items",
      queryBy: includeQueryName ? "name,aliases" : "aliases",
      filters: filters,
      page: page,
      perPage,
    });

    const resp = response.data as SearchResponse<TypesenseUserItem>;
    const data = resp.hits?.map((hit) => hit.document);
    return { data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getItemCostHistory(userId: string, itemId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const itemRef = collection(db, "users", userId, "itemCostHistory");
  const itemQuery = query(
    itemRef,
    where("itemId", "==", itemId),
    where("createdAt", ">=", moment().subtract(6, "months").toDate())
  );

  try {
    const querySnapshot = await getDocs(itemQuery);
    const data = querySnapshot.docs.map((doc) => doc.data() as ItemCostHistory);
    return { data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getUserItem(userId: string, itemId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const docRef = doc(db, "users", userId, "items", itemId);

  try {
    const docSnapshot = await getDoc(docRef);

    return { data: docSnapshot.data() as Items, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

interface IResolveZeroCostItemTask {
  userId: string;
  itemId: string;
  taskId: string;
  cost: number;
}

export async function resolveZeroCostItemTask({
  userId,
  itemId,
  taskId,
  cost,
}: IResolveZeroCostItemTask) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const itemRef = doc(db, "users", userId, "items", itemId);
  const taskRef = doc(db, "users", userId, "tasks", taskId);

  try {
    const batch = writeBatch(db);
    batch.update(itemRef, { cost });
    batch.update(taskRef, { deleted: true, updatedAt: new Date() });
    await batch.commit();
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error };
  }
}
