import { Invoice, InvoiceItem } from "redux/invoice/types";

export const percentageCheck = (item: InvoiceItem) => {
  const { oldCost, cost } = item;
  if (oldCost === cost) return false;
  const percentage = ((cost - oldCost) / oldCost) * 100;
  if (!!cost && !!oldCost && cost !== Infinity) {
    return (
      (percentage >= 15 || percentage <= -15) &&
      !(percentage >= 75 || percentage <= -75)
    );
  }
  return false;
};

export const percentage75Check = (item: InvoiceItem) => {
  const { oldCost, cost } = item;
  if (oldCost === cost) return false;
  const percentage = ((cost - oldCost) / oldCost) * 100;
  if (!!cost && !!oldCost && cost !== Infinity) {
    return percentage >= 75 || percentage <= -75;
  }
  return false;
};

export const benchmark25Check = (item: InvoiceItem) => {
  const { cost, costFromOrigin } = item;
  if (!costFromOrigin) return false;
  const isDifferent25 = Math.abs(cost - costFromOrigin) / cost >= 0.25;
  return isDifferent25;
};

export const newItemCheck = (item: InvoiceItem) => {
  return (
    !!item.isNew &&
    item.isNew === true &&
    !item.isOpenItem &&
    !item.isCreatedFromAlias
  );
};

export const nameChangedCheck = (item: InvoiceItem) => {
  return item.nameChanged;
};

export const openItemCheck = (item: InvoiceItem) => {
  return item.isOpenItem;
};

export const negativeCheck = (item: InvoiceItem) => {
  return item.cost < 0 || item.quantity < 0 || item.total < 0;
};

export const autoTotalfillItemsCheck = (item: InvoiceItem) => {
  return item.autoTotal;
};

export const autoQuantityfillItemsCheck = (item: InvoiceItem) => {
  return item.autoQuantity;
};

export const wasOpenItemCheck = (item: InvoiceItem) => {
  return item.wasOpenItem;
};

export const hasErrorCheck = (item: InvoiceItem) => {
  return item.hasError;
};

export const sponsoredItemCheck = (item: InvoiceItem) => {
  return item.isSponsored;
};

export const taxDeductionCheck = (item: InvoiceItem) => {
  return !!item.taxDeduction && item.totalBeforeDeduction !== item.total;
};

export const discountCheck = (item: InvoiceItem) => {
  return !!item.discount;
};

export const itemDuplicateCheck = (item: InvoiceItem) => {
  return item.isDuplicate;
};

export const itemTotalComputed = (item: InvoiceItem) => {
  return item.totalComputed;
};

export const isItemPrefilled = (item: InvoiceItem) => {
  return item.isIdReachConsensus && isVoteValueGetPrefilled(item);
};

export const isVoteValueGetPrefilled = (item: InvoiceItem) => {
  return (
    item.itemVoteValue &&
    item.itemVoteValue.quantity !== 0 &&
    item.itemVoteValue.total !== 0 &&
    item.itemVoteValue.cost !== 0
  );
};

export const isNewItemFromAliasSearch = (item: InvoiceItem) => {
  return item.isCreatedFromAlias;
};

export const isItemForUser = (item: InvoiceItem) => {
  return item.isItemForUser;
};

export const isErrorQuantity = (item: InvoiceItem) => {
  return Boolean(item.errorQuantity);
};

export const isErrorCost = (item: InvoiceItem) => {
  return Boolean(item.errorCost);
};

export const isErrorTotal = (item: InvoiceItem) => {
  return Boolean(item.errorTotal);
};

export const isErrorTotalUnmatched = (item: InvoiceItem) => {
  return Boolean(item.errorTotalUnmatched);
};

export const filterNoInvoiceComments = (invoice: Invoice) => {
  return !invoice.invoiceComments;
};

export const filterIsNotForSupervisor = (invoice: Invoice) => {
  return (
    invoice.tagsCount &&
    !Object.keys(invoice.tagsCount).some((tag) => tag === "tag-for-supervisor")
  );
};

export const filterNoOpenLine = (invoice: Invoice) => {
  return (
    invoice.tagsCount &&
    !Object.keys(invoice.tagsCount).some((tag) => tag === "openItem")
  );
};

export const filterNoOpenSupplier = (invoice: Invoice) => {
  return (
    invoice.tagsCount &&
    !Object.keys(invoice.tagsCount).some((tag) => tag === "tag-open-supplier")
  );
};

export const filterNo75PriceChange = (invoice: Invoice) => {
  return (
    invoice.tagsCount &&
    !Object.keys(invoice.tagsCount).some((tag) => tag === "percentChanged75")
  );
};

export const filterNoNewItem = (invoice: Invoice) => {
  return (
    invoice.tagsCount &&
    !Object.keys(invoice.tagsCount).some((tag) => tag === "new")
  );
};

export const filterNoNewItemFromAliasSearch = (invoice: Invoice) => {
  return (
    invoice.tagsCount &&
    !Object.keys(invoice.tagsCount).some((tag) => tag === "newFromAliasSearch")
  );
};

export interface ItemTag {
  name: string;
  text: string;
  filterFn: (item: InvoiceItem) => boolean | undefined;
  bgColorClass: string;
  exludeFromInitialTags?: boolean;
  hidden?: boolean;
}

export const TAG_COLORS = {
  ORANGE: "tag-orange",
  DARK_ORANGE: "tag-dark-orange",
  YELLOW: "tag-yellow",
  RED: "tag-red",
  ALT_NAME: "tag-alt-name",
  OPEN_ITEM: "tag-open-item",
  GREEN: "tag-green",
  GREEN_OLIVE: "tag-green-olive",
  WAS_OPEN_ITEM: "tag-was-open-item",
  HAS_ERROR: "tag-has-error",
  SPONSORED: "tag-sponsored",
  TAX_DEDUCTION: "tag-tax-deduction",
  DISCOUNT: "tag-discount",
  DUPLICATE: "tag-duplicate",
  TOTAL_COMPUTED: "tag-total-computed",
  UPLOADED_BY_USER: "tag-uploaded-by-user",
  DUPLICATE_INVOICE: "tag-duplicate-invoice",
  DIVERSE_INVOICE: "tag-diverse-invoice",
  FOR_SUPERVISOR: "tag-for-supervisor",
  DRAFT: "tag-draft",
  RESOLVED: "tag-resolved",
  DAYS_31: "tag-31-days",
  LESS_THAN_500: "tag-light-pink",
  MORE_THAN_100000: "tag-light-pink",
  INVOICE: "tag-invoice",
  OPEN_SUPPLIER: "tag-light-green",
  NEW_ALIASES: "tag-light-green",
  CREATED_BY_BOT: "tag-created-by-bot",
  CONSENSUS_REACHED: "tag-purple",
  CREATED_FROM_ALIAS_SEARCH: "tag-light-purple",
  ITEM_FOR_USER: "tag-light-green",
};

export const CUSTOM_TAGS = {
  UPLOADED_BY_USER: {
    name: "tag-uploaded-by-user",
    text: "Uploaded by User",
    bgColorClass: TAG_COLORS.UPLOADED_BY_USER,
  },
  DUPLICATE_INVOICE: {
    name: "tag-duplicate-invoice",
    text: "Duplicate Invoice",
    bgColorClass: TAG_COLORS.DUPLICATE_INVOICE,
  },
  DIVERSE_INVOICE: {
    name: "tag-diverse-invoice",
    text: "Diverse Invoice",
    bgColorClass: TAG_COLORS.DIVERSE_INVOICE,
  },
  FOR_SUPERVISOR: {
    name: "tag-for-supervisor",
    text: "For Supervisor",
    bgColorClass: TAG_COLORS.FOR_SUPERVISOR,
  },
  DRAFT: {
    name: "tag-draft",
    text: "Draft",
    bgColorClass: TAG_COLORS.DRAFT,
  },
  RESOLVED: {
    name: "resolved",
    text: "Resolved",
    bgColorClass: TAG_COLORS.RESOLVED,
  },
  DAYS_31: {
    name: "tag-31-days",
    text: "31 Days",
    bgColorClass: TAG_COLORS.DAYS_31,
  },
  LESS_THAN_500: {
    name: "tag-less-than-500",
    text: "Under 500",
    bgColorClass: TAG_COLORS.LESS_THAN_500,
  },
  MORE_THAN_100000: {
    name: "tag-more-than-100000",
    text: "Over 100K",
    bgColorClass: TAG_COLORS.MORE_THAN_100000,
  },
  OPEN_SUPPLIER: {
    name: "tag-open-supplier",
    text: "Open Supplier",
    bgColorClass: TAG_COLORS.OPEN_SUPPLIER,
  },
  NEW_ALIASES: {
    name: "tag-new-aliases",
    text: "New Aliases",
    bgColorClass: TAG_COLORS.NEW_ALIASES,
  },
  CREATED_BY_BOT: {
    name: "tag-created-by-bot",
    text: "Created by Bot",
    bgColorClass: TAG_COLORS.CREATED_BY_BOT,
  },
  CONSENSUS_REACHED: {
    name: "tag-consensus-reached",
    text: "Consensus Reached",
    bgColorClass: TAG_COLORS.CONSENSUS_REACHED,
  },
};

export const UNINTERESTING_INVOICE_TAGS = [
  {
    name: "noInvoiceComments",
    text: "No Invoice Comments",
    filterFn: filterNoInvoiceComments,
    bgColorClass: TAG_COLORS.YELLOW,
  },
  {
    name: "isNotForSupervisor",
    text: "Is Not For Supervisor",
    filterFn: filterIsNotForSupervisor,
    bgColorClass: TAG_COLORS.FOR_SUPERVISOR,
  },
  {
    name: "noOpenLine",
    text: "No open line",
    filterFn: filterNoOpenLine,
    bgColorClass: TAG_COLORS.ORANGE,
  },
  {
    name: "noOpenSupplier",
    text: "No open supplier",
    filterFn: filterNoOpenSupplier,
    bgColorClass: TAG_COLORS.OPEN_SUPPLIER,
  },
  {
    name: "no75PriceChange",
    text: "Not +75% price change",
    filterFn: filterNo75PriceChange,
    bgColorClass: TAG_COLORS.DARK_ORANGE,
  },
  {
    name: "noNewItem",
    text: "Not new item",
    filterFn: filterNoNewItem,
    bgColorClass: TAG_COLORS.GREEN_OLIVE,
  },
  {
    name: "notNewFromAliasSearch",
    text: "Not new item from alias search",
    filterFn: filterNoNewItemFromAliasSearch,
    bgColorClass: TAG_COLORS.CREATED_FROM_ALIAS_SEARCH,
  },
];

export const ITEM_TAGS = [
  {
    name: "percentChanged",
    text: "Change +/- 15%",
    filterFn: percentageCheck,
    bgColorClass: TAG_COLORS.ORANGE,
  },
  {
    name: "percentChanged75",
    text: "Change +/- 75%",
    filterFn: percentage75Check,
    bgColorClass: TAG_COLORS.DARK_ORANGE,
  },
  {
    name: "benchmark25",
    text: "Benchmark +/- 25%",
    filterFn: benchmark25Check,
    bgColorClass: TAG_COLORS.DARK_ORANGE,
  },
  {
    name: "new",
    text: "New",
    filterFn: newItemCheck,
    bgColorClass: TAG_COLORS.YELLOW,
  },
  {
    name: "negative",
    text: "Negative",
    filterFn: negativeCheck,
    bgColorClass: TAG_COLORS.RED,
    hidden: true,
  },
  {
    name: "nameChanged",
    text: "Name Changed",
    filterFn: nameChangedCheck,
    bgColorClass: TAG_COLORS.ALT_NAME,
  },
  {
    name: "openItem",
    text: "Open Item",
    filterFn: openItemCheck,
    bgColorClass: TAG_COLORS.OPEN_ITEM,
    hidden: true,
  },
  {
    name: "autoTotal",
    text: "Autofilled",
    filterFn: autoTotalfillItemsCheck,
    bgColorClass: TAG_COLORS.GREEN,
    hidden: true,
  },
  {
    name: "autoQuantity",
    text: "Qty Autofilled",
    filterFn: autoQuantityfillItemsCheck,
    bgColorClass: TAG_COLORS.GREEN_OLIVE,
    hidden: true,
  },
  {
    name: "wasOpenItem",
    text: "Was Open Item",
    filterFn: wasOpenItemCheck,
    bgColorClass: TAG_COLORS.WAS_OPEN_ITEM,
  },
  {
    name: "hasError",
    text: "Error",
    filterFn: hasErrorCheck,
    bgColorClass: TAG_COLORS.HAS_ERROR,
  },
  {
    name: "sponsored",
    text: "Sponsored",
    filterFn: sponsoredItemCheck,
    bgColorClass: TAG_COLORS.SPONSORED,
  },
  {
    name: "taxDeduction",
    text: "Tax Deduction",
    filterFn: taxDeductionCheck,
    bgColorClass: TAG_COLORS.TAX_DEDUCTION,
  },
  {
    name: "discount",
    text: "Discount",
    filterFn: discountCheck,
    bgColorClass: TAG_COLORS.DISCOUNT,
  },
  {
    name: "duplicate",
    text: "Duplicate",
    filterFn: itemDuplicateCheck,
    bgColorClass: TAG_COLORS.DUPLICATE,
    exludeFromInitialTags: true,
  },
  {
    name: "totalComputed",
    text: "Total Computed",
    filterFn: itemTotalComputed,
    bgColorClass: TAG_COLORS.TOTAL_COMPUTED,
    exludeFromInitialTags: true,
  },
  {
    name: "newFromAliasSearch",
    text: "New From Alias Search",
    filterFn: isNewItemFromAliasSearch,
    bgColorClass: TAG_COLORS.CREATED_FROM_ALIAS_SEARCH,
  },
  {
    name: "itemForUser",
    text: "Item For User",
    filterFn: isItemForUser,
    bgColorClass: TAG_COLORS.ITEM_FOR_USER,
    exludeFromInitialTags: true,
  },
  {
    name: "errorQuantity",
    text: "Error Quantity",
    filterFn: isErrorQuantity,
    bgColorClass: TAG_COLORS.RED,
    exludeFromInitialTags: true,
  },
  {
    name: "errorCost",
    text: "Error Cost",
    filterFn: isErrorCost,
    bgColorClass: TAG_COLORS.RED,
    exludeFromInitialTags: true,
  },
  {
    name: "errorTotal",
    text: "Error Total",
    filterFn: isErrorTotal,
    bgColorClass: TAG_COLORS.RED,
    exludeFromInitialTags: true,
  },
  {
    name: "errorisErrorTotalUnmatched",
    text: "Error Total Unmatched",
    filterFn: isErrorTotalUnmatched,
    bgColorClass: TAG_COLORS.RED,
    exludeFromInitialTags: true,
  },
  ...Object.values(CUSTOM_TAGS).map(
    (tag) =>
      ({
        ...tag,
        filterFn: () => false,
      }) as ItemTag
  ),
];

export const allTags = [
  ...ITEM_TAGS,
  ...UNINTERESTING_INVOICE_TAGS,
  ...Object.values(CUSTOM_TAGS),
];

export const defaultTagsCount = allTags
  .map((tag) => tag.name)
  .reduce(
    (acc, tag) => {
      acc[tag] = 0;
      return acc;
    },
    {} as Record<string, number>
  );
